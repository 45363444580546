import "phaser";

import { select } from "@ngrx/store";
import * as R from "ramda";
import {Subscription, withLatestFrom} from 'rxjs';
import { filter, take, tap } from "rxjs/operators";

import { EVENTS } from "../../../../../core/consts/core/events";
import { gameSetCurrentScene } from "../../../../../store/game/actions";
import {selectGameBoardInitiationData, selectGameCurrentPlayerIslandId} from '../../../../../store/game/selectors';
import { BOARD_TILE_HEIGHT, BoardViewMode, GAME_EVENTS, PHASER_CAMERA_ZOOM } from "../../../constants";
import { GameFeaturesMap } from "../../../constants/game-features";
import { IsoMap } from "../../classes/core/IsoMap";
import { IsoMapLayer } from "../../classes/core/IsoMapLayer";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { Board } from "../../classes/custom/Board.class";
import { CenterMap } from "../../interfaces/shared";
import { calculateMinZoomValue } from "../../utils/game.helper";
import { BASIC_BOOT_SCENE, BASIC_MAIN_SCENE } from "../basic.constants";
import { BASIC_SCENE_CONFIG } from "../basic-scene.config";
import {Region} from '../../../interfaces/region.interface';

export class BasicMainScene extends MyScene {
  private globalEmitterSub: Subscription;
  backgroundImages: Phaser.GameObjects.Image[];
  lastPlayerIslandID = null;
  regions: Region[] = [];

  constructor(sceneClassConfig?: Phaser.Types.Scenes.SettingsConfig) {
    sceneClassConfig = sceneClassConfig ? sceneClassConfig : { key: BASIC_MAIN_SCENE };
    super(sceneClassConfig);
    this.backgroundImages = [];
  }

  init(data?: any) {
    this.passedData = data;
    this.setCurrentScene();
  }

  preload(): void {
    super.preload();
    this.gameService = (this.game as MyGame).gameService;
    window["cameraObject"] = this.cameras.main;
  }

  createTiledMap() {
    this.isoMap = new IsoMap({
      scene: this,
    });

    if (this.sceneConfig.tiledMapFileName && GameFeaturesMap.tiledMap) {
      this.isoMap.createFromData(`${this.sceneConfig.mapDirectoryName}/${this.sceneConfig.tiledMapFileName}`);
    }
    this.buildingsLayer = this.isoMap.getLayerByName("buildings");

    if (!this.sceneConfig.tiledMapFileName || !this.buildingsLayer) {
      this.buildingsLayer = new IsoMapLayer({ scene: this, name: "buildingsLayer" });
    }

    this.roadsLayer = new IsoMapLayer({ scene: this, name: "roadsLayer" });
    this.roadsLayer.depthIndex = this.buildingsLayer.depthIndex - 1;

    this.topLayer = new IsoMapLayer({
      scene: this,
      depthIndex: 99,
      name: "top",
    });
  }

  provideSceneConfig() {
    if (this.passedData && this.passedData.sceneConfigKey) {
      return BASIC_SCENE_CONFIG[this.passedData.sceneConfigKey];
    }
    return BASIC_SCENE_CONFIG["default"];
  }

  create(): void {
    const sceneConfig = this.provideSceneConfig();
    this.sceneConfig = sceneConfig;
    this.phaserEvents = new Phaser.Events.EventEmitter();
    this.setCameraControls();
    this.cameras.main.setBackgroundColor("#b7e6eb");
    this.cameras.main.setBounds(
      this.sceneConfig.cameraBounds.x,
      this.sceneConfig.cameraBounds.y,
      this.sceneConfig.cameraBounds.width,
      this.sceneConfig.cameraBounds.height
    );

    if (GameFeaturesMap.sceneBgImages) {
      sceneConfig.backgroundImages.forEach(image => {
        const bgImage = this.add.image(image.x || 0, image.y || 0, image.key);
        bgImage.setDepth(image.z || 0);

        bgImage.setDisplaySize(image.displayWidth + 1, image.displayHeight + 1);

        if (!image.origin) {
          image.origin = {
            x: 0,
            y: 0,
          };
        }
        bgImage.setOrigin(image.origin.x, image.origin.y);

        this.backgroundImages.push(bgImage);
      });
    }

    this.createTiledMap();

    if (!this.buildingsLayer) {
      this.buildingsLayer = new IsoMapLayer({ scene: this, name: "buildingsLayer" });
    }

    if (GameFeaturesMap.board) {
      this.createBoard();
    } else {
      this.gameService.store.dispatch(gameSetCurrentScene({ sceneName: this.sceneConfig.configKeyName }));
    }

    this.topLayer = new IsoMapLayer({
      scene: this,
      depthIndex: 99,
      name: "top",
    });

    this.globalEmitterSub = this.gameService.globalService.globalEvents
      .pipe(
        tap(console.log),
        filter(event => event.name === EVENTS.GAME.CENTER_TILE)
      )
      .subscribe(event => this.focusOn(event.value));

    this.setCamera();
    this.afterCreate();
    this.gameService.globalService.globalEvents.emit({
      name: "SCENE_CONFIG_CHANGE",
      value: this.sceneConfig,
    });
  }

  focusOn(centerOn: CenterMap) {
    const targetTile = this.board.boardTiles.find(tile => tile.tileData.tile_id === centerOn.tile_id);
    this.gameService.store.select(selectGameCurrentPlayerIslandId).pipe(take(1)).subscribe(res => {
      const lastPlayerIslandId = res;

      if (!targetTile && lastPlayerIslandId !== centerOn.player_island_id) {
        // it means that tile is on the different level and we have to change the board
        this.gameService.centerOn = centerOn;
        this.gameService.guiService.isSplashShow.next(true);
        this.gameService.prepareGameData(this.gameService.playerService.getActivePlayerId(), centerOn.player_island_id);
        this.gameService.globalService.globalEvents.emit({
          name: GAME_EVENTS.MAP_CHANGE,
          value: {
            name: '',
            config: {
              configName: centerOn.config_name,
              islandId: centerOn.player_island_id,
            }
          }
        })
        return;
      } else {
        // it means that the tile on the current page
        this.gameService.guiService.isSplashShow.next(false);
      }

      this.add.tween({
        targets: this.cameras.main,
        duration: 700,
        ease: "Power2",
        scrollX: targetTile.x - this.cameras.main.width / 2,
        scrollY: targetTile.y - this.cameras.main.height / 2 - BOARD_TILE_HEIGHT / 2,
        zoom: 1,
        onUpdate: () => this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom),
      });
      this.gameService.centerOn = null;
    })
  }

  afterCreate() {}

  setCamera() {
    this.cameraStartPos = this.sceneConfig.cameraStartPosition;
    const centerTarget = this.board
      ? this.board.boardTiles.find(boardTile => boardTile.tileData.is_center_camera)
      : null;
    let zoomValue = 0.4;
    if (centerTarget) {
      this.cameraStartPos = {
        x: centerTarget.x,
        y: centerTarget.y,
      };
      zoomValue = 0;
    }
    this.cameras.main.centerOn(this.cameraStartPos.x, this.cameraStartPos.y);
    this.cameras.main.setZoom(Math.max(zoomValue, calculateMinZoomValue(this.cameras.main)));
    setTimeout(() => {
      this.phaserEvents.emit(PHASER_CAMERA_ZOOM, this.cameras.main.zoom);
    }, 50);
  }

  createBoard() {
    this.gameService.store
      .pipe(
        select(selectGameBoardInitiationData),
        filter(data => data.loaded == true && data.playerIslandId != null),
        withLatestFrom(this.gameService.playerService.playerRegions),
        take(1)
      )
      .subscribe(([data, regionData]) => {
        this.lastPlayerIslandID = data.playerIslandId;
        const clonedBoard = R.clone(data.boardTiles);
        this.regions = regionData.regions;
        this.board = new this.BoardClass(this, {
          offset: this.sceneConfig.boardStartOffset,
          yAxisSign: this.sceneConfig.yAxisSign,
        });
        this.board.createBoard(
          clonedBoard,
          this.sceneConfig.isOrtho ? BoardViewMode.Ortogonal : BoardViewMode.Isometric
        ).then(res => {
          this.gameService.store.dispatch(gameSetCurrentScene({ sceneName: this.sceneConfig.configKeyName }));
          this.gameService.guiService.isSplashShow.next(false);

          if (this.gameService.centerOn) {
            this.focusOn(this.gameService.centerOn);
          }
        })
      });
  }

  get BoardClass() {
    return Board;
  }

  destroyScene() {
    this.globalEmitterSub?.unsubscribe();
    super.destroyScene();
  }

  resize() {
    super.resize();
  }
}
