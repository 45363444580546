import { Component, Injectable, OnInit } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../../../core/abstracts/abstract-inject-base.component';
import { OwInject } from '../../../../../../../../core/decorators/ow-inject.decorator';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductDetailsBalance } from '../../../../../../../player/interfaces/product.interface';

@Component({
  selector: 'app-museum-artefact-details',
  templateUrl: './museum-artefact-details.component.html',
})
@Injectable()
export class MuseumArtefactDetailsComponent extends AbstractInjectBaseComponent implements OnInit {
  @OwInject(MAT_DIALOG_DATA) data: { artefact: ProductDetailsBalance, activeCollectionName: string };
  artefact: ProductDetailsBalance;
  collectionName: string;

  ngOnInit() {
    if (this.data) {
      this.artefact = this.data.artefact;
      this.collectionName = this.data.activeCollectionName;
    }
  }
}
