import { isPlatformBrowser } from "@angular/common";
import {
  AfterViewChecked,
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
  Input,
  OnChanges,
  PLATFORM_ID,
} from "@angular/core";

import { translate } from "../../../core/helpers/translate.helper";
import { DialogService } from "../providers/dialog.service";

@Directive({
  selector: "[owEllipsis]",
})
export class OwEllipsisDirective implements AfterViewInit, OnChanges, AfterViewChecked {
  @Input() innerText: string;
  @Input() labelMore = translate("ow-ellipsis.show-more-label");
  @Input() eventClass = "event-more";
  @Input() isDisabledClick: boolean;
  @Input() textAlign?: string;
  @Input() maxWidth?: string;
  @Input() maxHeight?: string;
  @Input() descMaxHeight?: string;
  @Input() isScrollbarOn?: boolean;
  @Input() filterWordsInDialog?: string[];

  viewGenerated = false;

  get el(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  @HostListener("click", ["$event"]) onClick(event) {
    if (!this.isDisabledClick && event.target && event.target.className.includes(this.eventClass)) {
      this.open();
    }
  }

  constructor(
    private readonly elementRef: ElementRef,
    private dialogService: DialogService,
    @Inject(PLATFORM_ID) private readonly platformId
  ) {}

  ngOnChanges(): void {
    this.truncate();
  }

  public ngAfterViewInit(): void {
    this.truncate();
  }

  public ngAfterViewChecked(): void {
    if (!this.viewGenerated) this.truncate();
  }

  @HostListener("window:resize")
  private onWindowResize() {
    this.truncate();
  }

  private truncate(): void {
    // verify execution context is the browser platform
    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    // store the original innerText
    if (this.innerText === undefined) {
      this.innerText = this.el.innerHTML.trim();
    }

    // reset the innerText
    this.el.innerHTML = this.innerText;

    // truncate the text and append the ellipsis
    const text = this.innerText.split(" ").slice(0, 60); // #16507 - optimize while iteration

    if (this.el.scrollHeight && this.el.clientHeight) {
      this.viewGenerated = true;
    }


    // scenario, where we want to check maxHeight of a parent; how many letters we can fit so it won't have a scrollbar(for intance - description)
    // @ts-ignore - unset is fine
    if (this.el.style.maxHeight !== '' || this.el.style.maxHeight === 'unset') {
      while (text.length > 0 && this.el.parentElement.scrollHeight > this.el.parentElement.clientHeight) {
        text.pop();
        this.el.innerHTML = `${text.join(" ")}${this.getElementEventDialog()}`;
      }
    } else {
      // scenario where element has set size(for instance - label, paragraf)
      while (text.length > 0 && this.el.scrollHeight > this.el.clientHeight) {
        text.pop();
        this.el.innerHTML = `${text.join(" ")}${this.getElementEventDialog()}`;
      }
    }
  }

  getElementEventDialog() {
    return `<span class="${this.eventClass}">${this.labelMore}</span>`;
  }

  open() {
    if (this.innerText !== this.el.innerText) {
      let text = this.innerText;

      // filter words like 'szczegóły'
      if (this.filterWordsInDialog?.length > 0) {
        for (let word of this.filterWordsInDialog) {
          text = text.replaceAll(word, '');
        }
      }

      this.dialogService.openAlert({
        description: text,
        style: {
          textAlign: this.textAlign,
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          descMaxHeight: this.descMaxHeight,
        },
        isScrollbarOn: this.isScrollbarOn,
      });
    }
  }
}
