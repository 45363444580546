import { createEntityAdapter } from "@ngrx/entity";
import { createReducer, on } from "@ngrx/store";
import moment from "moment";
import * as R from "ramda";

import { Message } from "../../modules/game/game-ui/message/interfaces/core/message.interface";
import {
  gameBoardTileUpdate,
  gameClearGameStatuses,
  gameEngineInitSuccess,
  gameFetchBoard,
  gameFetchBoardCacheSuccess,
  gameFetchBoardSuccess,
  gameGastroIncomeCollect,
  gameNewImportantMessageClosed,
  gameNewImportantMessageOpened,
  gameNewImportantMessageReceived,
  gamePreloadBoardBuildingsImagesSuccess,
  gameRemoveBoardStatus,
  gameResetBoard,
  gameSetCurrentPlayerIslandId,
  gameSetCurrentScene,
  gameSetGameReady,
} from "./actions";
import { BoardState } from "./interfaces/board.state";
import { BoardTileState } from "./interfaces/board-tile.state";
import { ImportantMessagesState } from "./interfaces/ImportantMessages.state";
import {
  checkDecorativeTilesToBeRendered,
  repositionChangeTilePosition
} from '../../modules/game/game-engine/utils/tile.helper';

export interface GameState {
  readyToLoad: boolean;
  readyToStart: boolean;
  ready: boolean;
  isLoading: boolean;
  boardState: BoardState;
  importantMessages: ImportantMessagesState;
  currentScene?: string;
}

const initialState: GameState = {
  ready: false,
  readyToStart: false,
  readyToLoad: false,
  isLoading: false,
  boardState: {
    isLoading: false,
    loaded: false,
    board: {
      ids: [],
      entities: {},
    },
    playerIslandId: null,
    buildingsImagesPreloaded: false,
    lastBoardTileUpdate: null,
    currentPlayerIslandId: null,
    boardStatus: {},
  },
  importantMessages: {
    messages: [],
    importantMessageDisplayed: false,
  },
  currentScene: null,
};

export const boardAdapter = createEntityAdapter<BoardTileState>({
  selectId: tile => tile.tile_id,
});

export const gameReducer = createReducer(
  initialState,
  on(gameFetchBoard, (state, action) => {
    return {
      ...state,
      boardState: {
        ...state.boardState,
        playerIslandId: action.playerIslandId,
        isLoading: true,
        loaded: false,
      },
    };
  }),
  on(gameFetchBoardSuccess, (state, action) => {
    const currentDate = moment().toISOString();
    const regionId = action.boardTiles?.[0]?.player_island_id;
    let copyBoard = structuredClone(action.boardTiles);
    let decoratives = [];
    for (let el of copyBoard) {
      el = repositionChangeTilePosition(el);

      // check if there's any decorative item to be rendered
      const dec = checkDecorativeTilesToBeRendered(el, copyBoard);
      if (dec) {
        decoratives = [...decoratives, ...dec];
      }
    }

    const newState = {
      ...state,
      boardState: {
        ...state.boardState,
        isLoading: false,
        loaded: true,
        boardStatus: {
          ...state.boardState.boardStatus,
        },
        board: boardAdapter.addMany([...copyBoard, ...decoratives], state.boardState.board),
      },
    };

    if (regionId) {
      newState.boardState.boardStatus[regionId] = {
        isReady: true,
        date: currentDate,
      };
    }
    return newState;
  }),
  on(gameFetchBoardCacheSuccess, (state, action) => {
    return {
      ...state,
      boardState: {
        ...state.boardState,
        playerIslandId: action.playerIslandId,
        loaded: true,
        isLoading: false,
      },
    };
  }),
  on(gameResetBoard, (state, action) => {
    return {
      ...state,
      ready: false,
      boardState: {
        ...state.boardState,
        boardStatus: state.boardState.boardStatus,
        board: state.boardState.board,
        loaded: false,
        loading: false
      },
    };
  }),
  on(gameEngineInitSuccess, (state, action) => {
    return {
      ...state,
      readyToLoad: action.payload,
    };
  }),
  on(gameClearGameStatuses, (state, action) => {
    return {
      ...state,
      boardState: {
        ...state.boardState,
        boardStatus: {},
      },
    };
  }),
  on(gameRemoveBoardStatus, (state, action) => {
    const boardStatusCopy = JSON.parse(JSON.stringify(state.boardState.boardStatus));
    delete boardStatusCopy[action.regionId];
    return {
      ...state,
      boardState: {
        ...state.boardState,
        boardStatus: boardStatusCopy,
      },
    };
  }),
  on(gameBoardTileUpdate, (state, action) => {
    const { type, ...tileData } = R.clone(action);
    const decoratives = checkDecorativeTilesToBeRendered(tileData, Object.values(state.boardState.board.entities));
    if (decoratives.length > 0) {
      return {
        ...state,
        boardState: {
          ...state.boardState,
          lastBoardTileUpdate: tileData,
          board: boardAdapter.addMany([...decoratives, tileData], state.boardState.board),
        },
      }
    }

    return {
      ...state,
      boardState: {
        ...state.boardState,
        lastBoardTileUpdate: tileData,
        board: boardAdapter.updateOne({ id: tileData.tile_id, changes: tileData }, state.boardState.board),
      },
    };
  }),
  on(gamePreloadBoardBuildingsImagesSuccess, (state, action) => {
    return {
      ...state,
      boardState: {
        ...state.boardState,
        buildingsImagesPreloaded: true,
      },
    };
  }),
  on(gameNewImportantMessageReceived, (state, action) => {
    return R.assocPath(
      ["importantMessages", "messages"],
      handleNewImportantMessage(action.message, R.clone(state.importantMessages.messages)),
      state
    );
  }),
  on(gameNewImportantMessageOpened, (state, action) => {
    return R.assocPath(["importantMessages", "importantMessageDisplayed"], action.opened, state);
  }),
  on(gameGastroIncomeCollect, (state, action) => {
    return state;
  }),
  on(gameSetGameReady, (state, action) => {
    return {
      ...state,
      ready: action.payload,
    };
  }),
  on(gameSetCurrentScene, (state, action) => {
    return {
      ...state,
      currentScene: action.sceneName,
      boardState: {
        ...state.boardState,
        playerIslandId: action.sceneName ? state.boardState.playerIslandId : null,
      }
    };
  }),
  on(gameNewImportantMessageClosed, (state, action) => {
    return R.assocPath(
      ["importantMessages"],
      {
        messages: R.clone(
          state.importantMessages.messages.filter(
            message => message.player_message_id != action.message.player_message_id
          )
        ),
        importantMessageDisplayed: false,
      },
      state
    );
  }),
  on(gameSetCurrentPlayerIslandId, (state, action) => {
    return {
      ...state,
      boardState: {
        ...state.boardState,
        currentPlayerIslandId: action.playerIslandId,
      },
    };
  })
);

function handleNewImportantMessage(newMessage: Message, currentMessages: Message[]): Message[] {
  currentMessages.unshift(newMessage);
  return currentMessages;
}
