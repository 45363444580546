import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MuseumCollectionDetailsComponent
} from '../../../base/custom/dialogs/museum-collection-details/museum-collection-details.component';
import { MuseumCollectionListComponent } from '../../../base/custom/dialogs/museum-collection-list/museum-collection-list.component';
import { SharedModule } from '../../../../../../shared/shared.module';
import { SharedUiMobileModule } from '../../../../shared-ui/mobile/shared-ui-mobile.module';
import { MuseumArtefactDetailsComponent } from '../../../base/custom/dialogs/museum-artefact-details/museum-artefact-details.component';

@NgModule({
  declarations: [
    MuseumCollectionDetailsComponent,
    MuseumCollectionListComponent,
    MuseumArtefactDetailsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    SharedUiMobileModule,
  ]
})
export class MuseumCollectionModule {
}
