import { SceneConfig } from '../interfaces/scene-config.interface';
import { BASIC_SCENE_BASE_CONFIG } from '../scenes-basic/basic-scene.base-config';
import {clone} from 'ramda';

export const DISCOVER_SCENE_CONFIG: SceneConfig | any = {
  ...clone(BASIC_SCENE_BASE_CONFIG),
  tiledStartOffset: {
    x: 0,
    y: 0
  },
  tiledJsonPrefix: null,
  backgroundImages: [],
  layeredMapJson: [
    {
      key: 'discover-map-shallow',
      path: 'map-discover/shallow/map.json'
    },
    {
      key: 'discover-map-city',
      path: 'map-discover/city/map.json'
    },
    {
      key: 'discover-map-medium',
      path: 'map-discover/medium/map.json'
    },
    {
      key: 'discover-map-deep',
      path: 'map-discover/deep/map.json'
    },
    {
      key: 'discover-map-titanic',
      path: 'map-discover/titanic/map.json'
    },
  ],
  mapDirectoryName: 'map-discover',
  tiledMapFileName: null,
  mapAtlasDirectory: 'map',
  islandGroupIdForBoard: 'C',
  boardStartOffset: {
    x: 0,
    y: 0
  },
  cameraBounds: {
    x: 0,
    y: 0,
    width: 4000,
    height: 2048
  },
  cameraStartPosition: {
    x: 1000,
    y: 500
  },
  isOrtho: true,
  yAxisSign: 1,
  other: {
    sceneZoom: {
      max: 0.45,
      min: 0.70
    },
    sceneSize: {
      width: 4000,
      height: 2048
    },
    gui_unlocks_button: 'discover_map',
    configKeyName: "discover"
  },
  configKeyName: "discover",
  tileMenuAtlasDirectory: 'tile-menu',
  tileTooltipConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
    maxZoomScaleValue: 1.5
  },
  tileMenuConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
    maxZoomScaleValue: 1.5
  },
  tileHoverConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
    maxZoomScaleValue: 2
  }
};
