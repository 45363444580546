import { Component, OnInit } from '@angular/core';
import { AbstractMuseumCollectionDetailsComponent } from '../../../../abstract/custom/abstract-museum-collection-details.component';

@Component({
  selector: 'app-museum-collection-details',
  templateUrl: './museum-collection-details.component.html',
})
export class MuseumCollectionDetailsComponent extends AbstractMuseumCollectionDetailsComponent implements OnInit {
  ngOnInit() {
    this.getAllCollections();
  }
}
