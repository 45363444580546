import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { AbstractMuseumCollectionListComponent } from '../../../../abstract/custom/abstract-museum-collection-list.component';
import {Swiper} from 'swiper/types';

@Component({
  selector: 'app-museum-collection-list',
  templateUrl: './museum-collection-list.component.html',
  styleUrls: ['./museum-collection-list.component.scss']
})
export class MuseumCollectionListComponent extends AbstractMuseumCollectionListComponent implements OnInit {
  @ViewChild('sliderMuseumCollection', {static: false}) sliderMuseumCollection: ElementRef | undefined;

  ngOnInit() {
    this.getCollectionList();
  }
}
