import { EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE } from "../../../../currency-exchange/consts/core/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_DAILY_QUESTIONS } from "../../../../daily-questions/consts/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_MESSAGE } from "../../../../message/consts/core/event-dialogs/event-names.const";
import { MESSAGE_TYPE_ID } from "../../../../message/consts/core/message-type.const";
import { EVENT_DIALOGS_NAMES_MISSION } from "../../../../mission/consts/core/event-dialogs/event-names";
import { EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM } from "../../../../rankings/consts/custom/event-dialogs/event-names.const";
import { EVENT_DIALOGS_NAMES_WAREHOUSE } from "../../../../warehouse/consts/core/event-dialogs/event-names.const";
import { MenuButtonConfig } from "../../interfaces/core/menu-buttonts-config.interface";
import {EVENT_DIALOGS_NAMES_CHESTS_CUSTOM} from '../../../../chests/consts/event-dialogs/event-names.const';
import {EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM} from '../../../../museum-collection/consts/custom/event-dialogs/event-names.const';

export const MENU_BUTTONS_CONFIG: MenuButtonConfig[] = [
  {
    type: "group",
    place: "menu-left",
    order: 1,
    name: "go-to",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: null,
    tooltip: "Idź do",
    icon: "location.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: 1, // very important -> check menu-buttons-config.service.ts
    onlyIsActiveMe: true,
    data: {
      type: "GROUP",
    },
  },
  {
    type: "button",
    place: "menu-left",
    order: 1,
    name: "city",
    gui_notification_type: null,
    gui_unlocks_button: "promenade_map",
    click_event: "world",
    tooltip: "Kurort",
    icon: "beach.png",
    show_only_active_me: true,
    group_name: "go-to",
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "MAP",
      dialogConfig: {
        configName: "promenade",
      },
    },
  },
  {
    type: "button",
    place: "menu-left",
    order: 1,
    name: "dive",
    gui_notification_type: null,
    gui_unlocks_button: "treasures_map",
    click_event: "treasures",
    tooltip: "Łowiska",
    icon: "dive.png",
    show_only_active_me: true,
    group_name: "go-to",
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "MAP",
      dialogConfig: {
        configName: "treasures",
      },
    },
  },
  {
    type: "button",
    place: "menu-left",
    order: 2,
    name: "missions",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: EVENT_DIALOGS_NAMES_MISSION.MISSIONS,
    tooltip: "Misje",
    icon: "mission.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
    data: {
      type: "DIALOG",
    },
  },
  {
    type: "button",
    place: "menu-left",
    order: 3,
    name: "messages",
    gui_notification_type: null,
    gui_unlocks_button: null,
    click_event: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
    tooltip: "Wiadomości",
    icon: "message.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
    data: {
      type: "DIALOG",
    },
  },
  // {
  //   type: 'group',
  //   place: 'menu-left',
  //   order: 3,
  //   name: 'go-to-resources',
  //   gui_notification_type: null,
  //   gui_unlocks_button: null,
  //   click_event: null,
  //   tooltip: 'Idź do',
  //   icon: 'resources.png',
  //   show_only_active_me: true,
  //   group_name: null,
  //   group_variant: 3,
  //   onlyIsActiveMe: true,
  //   data: {
  //     type: 'GROUP'
  //   }
  // },
  {
    type: "button",
    place: "menu-left",
    order: 4,
    name: "warehouse",
    gui_notification_type: null,
    gui_unlocks_button: "warehouse",
    click_event: EVENT_DIALOGS_NAMES_WAREHOUSE.WAREHOUSE,
    tooltip: "Magazyn",
    icon: "warehouse.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
    data: {
      type: "DIALOG",
    },
  },
  {
    type: 'button',
    place: 'menu-left',
    order: 5,
    name: 'chests',
    gui_notification_type: null,
    gui_unlocks_button: 'chests',
    click_event: EVENT_DIALOGS_NAMES_CHESTS_CUSTOM.CHESTS,
    tooltip: 'Skrzynie',
    icon: 'chest.png',
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "DIALOG"
    }
  },
  {
    type: "button",
    place: "menu-top",
    order: 6,
    name: "museum_collection",
    gui_notification_type: null,
    gui_unlocks_button: "museum",
    click_event: EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.MUSEUM_COLLECTION_LIST,
    tooltip: "Kolekcja muzealna",
    icon: "exposure_value.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "DIALOG",
    },
  },
  {
    type: "button",
    place: "menu-left",
    order: 7,
    name: "qa-daily",
    gui_notification_type: null,
    gui_unlocks_button: "daily_questions",
    click_event: EVENT_DIALOGS_NAMES_DAILY_QUESTIONS.QUESTION_LIST,
    tooltip: "Pytania codzienne",
    icon: "questions.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "DIALOG",
    },
  },
  {
    type: "button",
    place: "menu-left",
    order: 8,
    name: "qa",
    gui_notification_type: null,
    gui_unlocks_button: "qa",
    click_event: EVENT_DIALOGS_NAMES_MESSAGE.MESSAGES,
    tooltip: "Quizy",
    icon: "quiz.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "DIALOG",
      dialogConfig: {
        type: MESSAGE_TYPE_ID.QA,
        title: " ",
      },
    },
  },
  {
    type: "button",
    place: "menu-top",
    order: 9,
    name: "rankings",
    gui_notification_type: null,
    gui_unlocks_button: "ranks",
    // gui_unlocks_blocked_tooltip: true,
    click_event: EVENT_DIALOGS_NAMES_RANKINGS_CUSTOM.RANKING_LIST,
    tooltip: "Rankingi",
    icon: "rankings.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: null,
    data: {
      type: "DIALOG",
    },
  },
  {
    type: "button",
    place: "menu-top",
    order: 10,
    name: "photobooth",
    gui_notification_type: null,
    gui_unlocks_button: "photobooth",
    // gui_unlocks_blocked_tooltip: true,
    click_event: "photobooth",
    tooltip: "Fotowrzutka",
    icon: "photobooth.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "ROUTE",
      route: "auth/gallery/gallery",
    },
  },
  {
    type: "button",
    place: "menu-top",
    order: 10,
    name: "currency_exchange",
    gui_notification_type: null,
    gui_unlocks_button: "exchange",
    // gui_unlocks_blocked_tooltip: null,
    click_event: EVENT_DIALOGS_NAMES_CURRENCY_EXCHANGE.CURRENCY_EXCHANGE,
    tooltip: "Kantor",
    icon: "exchange.png",
    show_only_active_me: true,
    group_name: null,
    group_variant: null,
    onlyIsActiveMe: true,
    data: {
      type: "DIALOG",
    },
  },
];
