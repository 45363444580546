import {CLOUDS_CONFIG, TREASURES_MAIN_SCENE} from '../treasures.constants';
import {BasicMainScene} from '../../scenes-basic/core/Basic.main.scene';
import {WeatherSystem} from '../classes/WeatherSystem';
import {mobileGui} from '../../../../../core/providers/device.service';;
import {TreasureBoard} from '../overrides/TreasureBoard.class';

export class TreasuresMainScene extends BasicMainScene {
  configKeyName = "treasures";
  weatherSystem: WeatherSystem;

  constructor() {
    super({
      key: TREASURES_MAIN_SCENE,
    });
  }

  preload() {
    super.preload();
    this.load.image(
      'cloud',
      this.gameService.assetsService.getAssetPath('map-treasures/cloud.png', 'Cloud inside Treasures.main.scene.ts')
    );
  }

  afterCreate() {
    super.afterCreate();

    if (!mobileGui) {
      this.weatherSystem = new WeatherSystem(this, CLOUDS_CONFIG);
    }
  }

  get BoardClass() {
    return TreasureBoard;
  }
}
