import {BoardTile} from '../../classes/custom/BoardTile.class';
import {AddDecorativeBuildingsClass} from '../classes/AddDecorativeBuildings.class';
import {PROMENADE_ROAD_GROUP_NAME} from '../promenade.constants';
import {MyScene} from '../../classes/core/MyScene';

export const PromenadeCreationTriggers = {
  99: {
    name: "normal_promenade",
    generateFnName: 'promenade_roads',
  },
  100: {
    name: "normal_promenade",
    generateFnName: 'promenade_roads'
  },
  101: {
    name: "normal_promenade",
    generateFnName: 'promenade_roads'
  },
  102: {
    name: "normal_promenade",
    generateFnName: 'promenade_roads'
  },
  103: {
    name: "normal_promenade",
    generateFnName: 'promenade_roads'
  },
}

export const PromenadeCreators = {
  'promenade_roads': (tile: BoardTile, scene: MyScene) => {
    const promenade = new AddDecorativeBuildingsClass();
    return promenade.generateBoardTiles(tile)
  },
}

export const PromenadeCleanup = {
  'promenade_roads': (tiles: BoardTile[]) => {
    return tiles.filter(x => x?.tileData?.player_building?.group_type === PROMENADE_ROAD_GROUP_NAME);
  }
}
