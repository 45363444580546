import { EventDialog } from 'src/app/core/interfaces/event-dialog.interface';
import { MuseumCollectionListComponent } from '../../../base/custom/dialogs/museum-collection-list/museum-collection-list.component';
import {
  MuseumCollectionDetailsComponent
} from '../../../base/custom/dialogs/museum-collection-details/museum-collection-details.component';
import { EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM } from './event-names.const';
import { MuseumArtefactDetailsComponent } from '../../../base/custom/dialogs/museum-artefact-details/museum-artefact-details.component';

export const EVENT_DIALOGS_MUSEUM_COLLECTION_CUSTOM: EventDialog = {
  [EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.MUSEUM_COLLECTION_LIST]: MuseumCollectionListComponent,
  [EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.MUSEUM_COLLECTION_DETAILS]: MuseumCollectionDetailsComponent,
  [EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.ARTEFACT_DETAILS]: MuseumArtefactDetailsComponent,
};
