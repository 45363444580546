import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { TASK_TYPE } from "../../consts/custom/task-type.const";
import { Task } from "../../interfaces/custom/task.interface";

export function setTaskClasses(task: Task): string[] {
  const classes: string[] = [];

  if (task.performance !== null) {
    // MIN VALID
    let minValid = null;

    if (task.min_target !== null) {
      minValid = task.min_value >= task.min_target;
    }

    switch (minValid) {
      case true:
        classes.push("have-min-valid-true");
        break;

      case false:
        classes.push("have-min-valid-false");
        break;

      case null:
        classes.push("no-min-valid");
        break;
    }

    // REALIZATION
    if (task.realization >= 1) {
      classes.push("realization-up-100");
    } else {
      classes.push("realization-down-100");
    }
  }

  // CARD TEMPLATE
  switch (task.card_template) {
    case CARD_TEMPLATE.S1:
      classes.push("card-template-s1");
      break;

    case CARD_TEMPLATE.S2:
      classes.push("card-template-s2");
      break;

    case CARD_TEMPLATE.S3:
      classes.push("card-template-s3");
      break;
  }

  // CARD TASK TYPE
  switch (task.task_type) {
    case TASK_TYPE.DAILY:
      classes.push("card-a");
      break;

    case TASK_TYPE.WEEKLY:
    case TASK_TYPE.BI_WEEKLY:
    case TASK_TYPE.OPEARATION_WEEKLY:
      classes.push("card-b");
      break;

    case TASK_TYPE.MONTHLY:
      classes.push("card-c");
      break;
  }

  return classes;
}
