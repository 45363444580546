import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractChestsComponent } from '../../../../abstract/custom/abstract-chests.component';
import { unsubscribeObject } from '../../../../../../../../core/utility/unsubscribe-array';

@Component({
  selector: 'app-chests',
  templateUrl: './chests.component.html',
})
export class ChestsComponent extends AbstractChestsComponent implements OnInit, OnDestroy {

  ngOnInit() {
    this.getProductsByCategory();
    this.clearScrollTops();
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
