import { Cloud, CloudConfig } from './Cloud';
import { MyScene } from '../../classes/core/MyScene';
import { PHASER_CAMERA_ZOOM } from '../../../constants';

export class WeatherSystem {
  scene: MyScene;
  windSpeed = 0.3;
  cameraZoom: number;
  clouds: Cloud[] = [];
  shadows: Cloud[] = [];
  cloudsVisible = true;

  constructor(scene: MyScene, cloudsConfig: CloudConfig[] = []) {
    this.scene = scene;

    let cloud;
    let shadow;
    let rotation;
    cloudsConfig.forEach(config => {
      rotation = this.setRotation();
      shadow = new Cloud(this.scene, config.x + 2, config.y + 2, config.z, this.windSpeed, 'cloud', true, rotation);
      cloud = new Cloud(this.scene, config.x, config.y, config.z, this.windSpeed, 'cloud', false, rotation);
      this.clouds.push(cloud);
      this.shadows.push(shadow);
    });

    this.subscribeCameraZoom();
  }

  subscribeCameraZoom() {
    let timer;
    this.scene.phaserEvents.on(PHASER_CAMERA_ZOOM, (zoom: number) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.setCloudsZoom(zoom);
      }, 50);
    });
  }

  setCloudsZoom = (zoom) => {
    if (window.devicePixelRatio >= 2) {
      if (zoom >= 0.50) {
        this.hideClouds();
      } else {
        this.showClouds();
      }
    } else {
      if (zoom >= 0.30) {
        this.hideClouds();
      } else {
        this.showClouds();
      }
    }
  }

  hideClouds = () => {
    this.clouds.forEach((cloud) => {
      this.setHideCloudAnimation(cloud);
    });
    this.shadows.forEach((shadow) => {
      this.setHideCloudAnimation(shadow);
    });
    this.cloudsVisible = false;
  }

  showClouds = () => {
    if (this.cloudsVisible) {
      return;
    }

    this.clouds.forEach((cloud) => {
      this.setShowCloudAnimation(cloud);
    });
    this.shadows.forEach((shadow) => {
      this.setShowShadowAnimation(shadow);
    });
    this.cloudsVisible = true;
  }

  setRotation() {
    return Math.random();
  }

  setShowCloudAnimation = (cloud) => {
    this.scene.tweens.add({
      targets: cloud,
      yoyo: false,
      repeat: 0,
      alpha: 1,
      duration: 1000,
      ease: 'Linear',
    });
  }

  setShowShadowAnimation = (cloud) => {
    this.scene.tweens.add({
      targets: cloud,
      yoyo: false,
      repeat: 0,
      alpha: 0.7,
      duration: 1000,
      ease: 'Linear',
    });
  }

  setHideCloudAnimation = (cloud) => {
    this.scene.tweens.add({
      targets: cloud,
      yoyo: false,
      repeat: 0,
      alpha: 0,
      duration: 1000,
      ease: 'Linear',
    });
  }
}
