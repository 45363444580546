import {BoardCore} from '../../classes/core/BoardCore.class';
import {DiscoverTileClass} from './DiscoverTile.class';

export class DiscoverBoard extends BoardCore {
  boardTiles: DiscoverTileClass[] = [];

  get BoardTileClass() {
    return DiscoverTileClass;
  }
}
