import { Injectable } from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { Collection, MuseumSetDetails } from '../../interfaces/custom/museum-collection.interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { ApiMuseumCollectionService } from '../../api/custom/services/api-museum-collection.service';
import { EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM } from '../../consts/custom/event-dialogs/event-names.const';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { ProductDetailsBalance } from '../../../../../player/interfaces/product.interface';
import { ProductPlayerService } from '../../../../../player/providers/product-player.service';
import { EVENT_DIALOGS_NAMES_CHESTS_CUSTOM } from '../../../chests/consts/event-dialogs/event-names.const';

@Injectable()
export abstract class AbstractMuseumCollectionDetailsComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractMuseumCollectionDetailsComponent>;
  @OwInject(ApiMuseumCollectionService) apiMuseumCollectionService: ApiMuseumCollectionService;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(MAT_DIALOG_DATA) data: { collection: Collection, index?: number };
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;

  collections: Collection[];
  activeCollection: Collection;
  currentPage = 0;
  set_id: number;
  items: (ProductDetailsBalance & { is_collected: boolean } | {})[];
  fillNumberItems = 3;
  set: MuseumSetDetails;

  getAllCollections() {
    this.apiMuseumCollectionService.getPlayerSets()
      .subscribe(
        (resp: Collection[]) => {
          this.collections = resp;
          this.afterAllCollections();
        });
  }

  afterAllCollections() {
    let dataCollection;

    if (this.data.collection) {
      dataCollection = this.searchCollectionById(this.data.collection.museum_set.set_id);
    }

    if (dataCollection) {
      this.changePage(this.collections.indexOf(dataCollection));
    }
  }

  searchCollectionById(id: number) {
    return this.collections.find((collection) => {
      return id === collection.museum_set.set_id;
    });
  }

  setCollectionByIndex(index: number) {
    const collection = this.searchCollectionByIndex(index);

    if (collection) {
      this.changeActiveCollection(collection);
    }
  }

  changePage(page) {
    this.currentPage = page;
    this.setCollectionByIndex(page);
  }

  changeActiveCollection(collect) {
    this.activeCollection = collect;
    this.set_id = this.activeCollection.museum_set.set_id;
    this.getSetDetails(this.set_id);
  }

  getSetDetails(id) {
    this.apiMuseumCollectionService.getPlayerSet(id)
      .subscribe(
        (resp: MuseumSetDetails[]) => {
          const respMapped = resp.map((item) => {
            item['amount'] = 1;
            return item;
          });
          this.items = this.productPlayerService.getProducts(respMapped);
          this.fillRow();
        }
      );
  }

  fillRow() {
    const toFill = this.fillNumberItems - this.items.length % this.fillNumberItems;

    for (let i = 0; i < toFill; i++) {
      this.items.push({});
    }
  }

  searchCollectionByIndex(index: number) {
    return this.collections[index];
  }

  openArtefactDetails(item, name) {
    if (item.is_collected) {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.ARTEFACT_DETAILS,
        config: {
          data: {
            artefact: item,
            activeCollectionName: name,
          }
        }
      });
    }
  }

  getCollectionReward() {
    this.apiMuseumCollectionService.getMuseumReward(this.activeCollection.museum_set.set_id)
      .subscribe((resp) => {
        if (resp.player_chest_id) {
          this.dialogService.closeAll();
          setTimeout(() => {
            this.eventEmitterDialogsService.emitter.emit({
              name: EVENT_DIALOGS_NAMES_CHESTS_CUSTOM.CHESTS,
              config: {
                data: {
                  playerChestId: resp.player_chest_id
                }
              }
            });
          });
        } else {
          this.data.collection = this.activeCollection;
          this.getAllCollections();
          this.dialogService.openAlert({
            title: 'Sukces!',
            description: 'Nagroda za kolekcję znajduje się w magazynie skrzyń.'
          });
        }
      });
  }

  backToList() {
    this.dialogService.closeActive();
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.MUSEUM_COLLECTION_LIST,
        config: {
          data: {
            index: this.collections.indexOf(this.searchCollectionById(this.activeCollection.museum_set.set_id)),
            isOpenByCollectionsList: true,
          }
        }
      });
    });
  }
}
