import { Currency } from "../../../../../../core/interfaces/currency";
import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { Task } from "../../interfaces/custom/task.interface";

export function setPrizesToDisplayHelper(task: Task): Currency[] {
  if (task.card_template === CARD_TEMPLATE.S1) {
    let summedScoped: Currency[] = [];
    task.scopes.forEach(scope => {
      if (scope.default) {
        summedScoped = scope.currency_prizes;
      }
    });
    return summedScoped;
  }

  if (task.card_template === CARD_TEMPLATE.S2 || task.card_template === CARD_TEMPLATE.S3) {
    const summedPrizesProgressive: Currency[] = [];
    task.progressive_currency_prizes.forEach(progressive_currency => {
      if (task.task_definition.value_type === 1) {
        summedPrizesProgressive.push({
          currency_id: progressive_currency.currency_id,
          amount: +(
            progressive_currency.unit_amount *
            (+progressive_currency.max_realization_scope - +progressive_currency.min_realization_scope + 1)
          ).toFixed(),
        });
      }
      if (task.task_definition.value_type === 2) {
        summedPrizesProgressive.push({
          currency_id: progressive_currency.currency_id,
          amount: +(
            progressive_currency.unit_amount *
            (+progressive_currency.max_realization_scope * 100 - +progressive_currency.min_realization_scope * 100 + 1)
          ).toFixed(),
        });
      }
    });
    let bonusPrize: Currency[] = [];
    task.scopes.forEach(scope => {
      if (scope.default) {
        bonusPrize = scope.currency_prizes;
      }
    });

    const allPrizesS2: Currency[] = [];

    bonusPrize.forEach(bonus_currency => {
      if (bonus_currency.amount && bonus_currency.currency_id) {
        allPrizesS2.push(bonus_currency);
      }
    });
    summedPrizesProgressive.forEach(progressive_currency => {
      if (progressive_currency.amount && progressive_currency.currency_id) {
        allPrizesS2.push(progressive_currency);
      }
    });

    const allIdsS2: number[] = [];

    allPrizesS2.forEach(ele => {
      if (!allIdsS2.includes(ele.currency_id)) {
        allIdsS2.push(ele.currency_id);
      }
    });

    const summedPrizesS2: Currency[] = [];

    allIdsS2.forEach(id => {
      let amount = 0;
      allPrizesS2.forEach(prizes => {
        if (id === prizes.currency_id) {
          amount += prizes.amount;
        }
      });
      summedPrizesS2.push({ currency_id: id, amount: amount });
    });
    return summedPrizesS2;
  }
}
