<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- BUTTON EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BAR -->
  <div class="back-bar">
    <button (click)="backToList()" class="square normal primary">
      <i class="svg-icon angle-left"></i>
    </button>
  </div>

  <!-- ALL COLLECTION LIST-->
  <ng-container *ngIf="activeCollection">

    <!-- TOP BAR BASE -->
    <div class="top-bar">
      <div class="title" [innerHTML]="activeCollection.museum_set.name"></div>
    </div>

    <div class="container loading-opacity">
      <div class="products-collected">
        <div class="artefact" [class.empty-card]="!item.product_id" *ngFor="let item of items">
          <ng-container *ngIf="item.product_id">
            <div class="card" [class.empty]="!item.is_collected"
                 (click)="openArtefactDetails(item, activeCollection.museum_set.name)">
              <ng-container *ngIf="item.is_collected; else emptyTpl">
                <!-- ITEM NAME -->
                <p class="name" *ngIf="item.is_collected"> {{item.name}}</p>

                <!-- ITEM ICON -->
                <div class="icon-production">
                  <img *ngIf="item.is_collected" [src]="item.iconUrlBig"/>
                </div>
              </ng-container>

              <!-- ITEM WORTH -->
              <div class="worth" [class.check]="item.is_collected">
                <img class='exposure' [src]="'exposure_value.png' | asset: 'parameters/hud'">
                <span>+ {{item.worth}}</span>
              </div>

              <!-- NO ITEM -->
              <ng-template #emptyTpl>
                <i class="fas fa-question"></i>
              </ng-template>
            </div>
          </ng-container>
        </div>
      </div>

      <!-- ARROW -->
      <div class="arrow">
        <img [src]="'arow-green.png' | asset: 'ui'"/>
      </div>

      <!-- PRIZE -->
      <div class="prize">

        <!-- TITLE -->
        <p class="prize-title">
          Nagroda <br/> za zebranie kolekcji
        </p>

        <!-- BONUS -->
        <p class="bonus">
          <img class='exposure' [src]="'exposure_value.png' | asset: 'parameters/hud'">
          <span>+{{ activeCollection.museum_set.worth }}</span>
        </p>

        <!-- SPECIAL CHEST -->
        <img class="chest" [src]="'chest_special_base_small.png' | asset: 'chest'"/>

        <!-- GET REWARD -->
        <ng-container *ngIf="activeCollection as ac">
          <button
            class="base primary reward"
            (click)="getCollectionReward()"
            *ngIf="(ac.museum_set.completed_set_reward && !ac.museum_set.completed_set_reward.has_received) || !ac.museum_set.completed_set_reward"
            [disabled]="(ac.obtained_items !== ac.total_items && ac.museum_set.completed_set_reward && !ac.museum_set.completed_set_reward.has_received) || !ac.museum_set.completed_set_reward">
            Odbierz
          </button>
          <img
            *ngIf="ac.museum_set.completed_set_reward && ac.museum_set.completed_set_reward.has_received"
            [src]="'icon-check.png' | asset: 'ui'"
            class="has-received"
          />
        </ng-container>
      </div>
    </div>

    <!-- NAVIGATION -->
    <div class="navigation">
      <!-- PREV -->
      <div class="prev-coll">
        <ng-container *ngIf="currentPage != 0">
          <button
            class="square small primary prev"
            (click)="changePage(currentPage-1)"
          >
            <i class="svg-icon angle-left"></i>
          </button>
          Poprzednia kolekcja
        </ng-container>
      </div>
      <!-- NEXT -->
      <div class="next-coll">
        <ng-container *ngIf="currentPage < collections.length - 1">
          Następna kolekcja
          <button
            class="square small primary next"
            (click)="changePage(currentPage+1)"
          >
            <i class="svg-icon angle-right"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>
