import {BasicMainScene} from '../../scenes-basic/core/Basic.main.scene';
import {PROMENADE_MAIN_SCENE} from '../promenade.constants';

export class PromenadeMainScene extends BasicMainScene {
  configKeyName = "promenade";

  constructor() {
    super({ key: PROMENADE_MAIN_SCENE });
  }
}
