import TextStyle = Phaser.Types.GameObjects.Text.TextStyle;

export const TREASURES_MAIN_SCENE = 'TREASURES_MAIN_SCENE';
export const BUILDING_GROUP_TYPE_HARBOR = 'hist_beach_divers2';
export const BUILDING_GROUP_TYPE_DIVE = 'dive';
export const TRIOX_STRING = 'triox';

export const TREASURES_TILE_MENU_STYLES: TextStyle = {
  color: '#4d4d4d',
  fontSize: 28,
  fontFamily: 'Mikado',
  align: 'center',
  fontStyle: 'bold',
  wordWrap: { useAdvancedWrap: true, width: 300},
};

export const TREASURES_TILE_ARCHIVO = {
  ...TREASURES_TILE_MENU_STYLES,
  fontWeight: 'normal',
  fontFamily: 'Archivo Narrow',
};

export const CLOUDS_CONFIG = [
  {
    x: -3500,
    y: 3600,
    z: 5000,
  },
  {
    x: -4000,
    y: 6000,
    z: 5000
  },
  {
    x: 1000,
    y: 5000,
    z: 5000
  },
  {
    x: 1500,
    y: 9000,
    z: 5000
  },
  {
    x: 1000,
    y: 10500,
    z: 5000
  },
];
