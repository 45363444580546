import {BoardTileCore} from '../../classes/core/BoardTileCore.class';
import {TreasuresTileState} from '../treasures.interfaces';
import {TRIOX_STRING} from '../treasures.constants';
import {MAP_ATLAS_NAME} from '../../../constants';
import {Scene} from 'phaser';
import {SceneConfig} from '../../interfaces/scene-config.interface';
import {TreasureTileMenuClass} from './TreasureTileMenu.class';

export class TreasureTileClass extends BoardTileCore {
  scene: Scene & { sceneConfig: SceneConfig };
  tileMenu: TreasureTileMenuClass;

  handleBuildingStatus() {
    if (!this.gameService.playerService.isActiveMe) {
      return;
    }

    super.handleBuildingStatus();

    const innerIslandResources = (this.tileData as TreasuresTileState).inner_island_player_resource_summary;
    if (this.scene.sceneConfig.configKeyName === 'treasures' && this.hasBuilding && innerIslandResources) {
      let buoy;

      if (innerIslandResources.retrieved === innerIslandResources.amount) {
        buoy = 'buoy-red.png';
      } else if (innerIslandResources.retrieved < innerIslandResources.amount) {
        buoy = 'buoy-green.png';
        if (this.playerBuildingData.name.toLowerCase().indexOf(TRIOX_STRING) > -1) {
          buoy = 'buoy-blue.png';
        }
      }

      if (buoy) {
        const buoySprite = this.scene.add.image(-50, 0, MAP_ATLAS_NAME, buoy);
        buoySprite.setOrigin(0.2, 1);
        this.add(buoySprite);
      }
    }
  }

  get TileMenuClass() {
    return TreasureTileMenuClass;
  }
}
