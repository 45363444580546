import {BoardCore} from '../../classes/core/BoardCore.class';
import {Ship} from '../classes/Ship';
import {BUILDING_GROUP_TYPE_DIVE, BUILDING_GROUP_TYPE_HARBOR} from '../treasures.constants';
import {TreasuresTileState} from '../treasures.interfaces';
import moment from 'moment/moment';
import {TreasureTileClass} from './TreasureTile.class';

export class TreasureBoard extends BoardCore {
  ship: Ship;
  boardTiles: TreasureTileClass[] = [];

  beforeTilesCreation() {
    super.beforeTilesCreation();
    this.handleShip();
  }

  onTileRefresh() {
    super.onTileRefresh();
    this.handleShip();
  }

  get BoardTileClass() {
    return TreasureTileClass;
  }

  handleShip() {
    const harborTile = this.boardTiles.find(
      tile => tile.hasBuilding && tile.tileData.player_building.group === 'hist_beach_divers2'
    );
    if (harborTile) {
      const ship = this.ship;
      if (!ship) {
        this.ship = new Ship(this.myScene, harborTile);
      }

      let tileWithLatestAction;
      let latestAction;
      this.boardTiles
        .filter(tile => tile.playerBuildingData && BUILDING_GROUP_TYPE_DIVE === tile.playerBuildingData.group_type)
        .forEach(tile => {
          const innerIslandPlayerBuildingInProgress = (tile.tileData as TreasuresTileState)
            .inner_island_player_building_in_progress;
          if (
            tile.isBuildInProgress() ||
            (Boolean(innerIslandPlayerBuildingInProgress) && Boolean(innerIslandPlayerBuildingInProgress.built_at))
          ) {
            if (!tileWithLatestAction) {
              tileWithLatestAction = tile;
              latestAction = tile.isBuildInProgress()
                ? tile.playerBuildingData.built_at
                : tile.tileData.inner_island_player_building_in_progress.built_at;
            }

            if (tile.isBuildInProgress() && moment(tile.playerBuildingData.built_at).diff(latestAction) < 0) {
              tileWithLatestAction = tile;
              latestAction = tile.playerBuildingData.built_at;
            }
            if (
              Boolean((tile.tileData as TreasuresTileState).inner_island_player_building_in_progress) &&
              moment((tile.tileData as TreasuresTileState).inner_island_player_building_in_progress).diff(
                latestAction
              ) < 0
            ) {
              tileWithLatestAction = tile;
              latestAction = tile.tileData.inner_island_player_building_in_progress.built_at;
            }
          }
        });

      if (tileWithLatestAction !== undefined) {
        this.ship.sendToTile(tileWithLatestAction);
      } else {
        this.ship.sendHome();
      }
    }
  }

  destroy() {
    this.ship?.destroy();
    super.destroy();
  }
}
