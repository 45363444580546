<div class="main-buttons-container" *ngIf="playerService.player">
    <div class="player-info-container"
         *ngIf="!playerService.isActiveMe">
        <button class="square small primary prev"
                (click)="previewPlayerService.changePlayer()">
            <i class="svg-icon angle-left"></i>
        </button>

        {{ playerService.player.first_name }} {{ playerService.player.last_name }}
    </div>

    <div class="main-buttons-container" *ngIf="playerService.player">
        <ng-container *ngFor="let menuButton of menuButtons; let i = index">
            <ng-container
                    *ngIf="menuButton.order <= MENU_BUTTONS_ORDER_DISPLAY || !otherButtonsIsActive"
                    [ngTemplateOutlet]="buttonTpl"
                    [ngTemplateOutletContext]="{ button: menuButton }"></ng-container>
        </ng-container>

        <!--MORE-->
        <ng-container *ngIf="otherButtonsIsActive" [ngTemplateOutlet]="moreButtonTpl"></ng-container>
    </div>
</div>

<!-- MORE BUTTON TPL -->
<ng-template #moreButtonTpl>
    <div class="buttons-vertical"
         *ngIf="playerService.isActiveMe"
         [ngClass]="{'fixed': menuMoreWasClicked}">
        <button
                class="main-button"
                matTooltip="Więcej"
                [matTooltipShowDelay]="300"
                [matTooltipPosition]="isDialog ? 'below' : 'right'"
                (click)="handleMenuMoreEvent()">
            <img [src]="'more.png' | asset: 'ui/menu/left'" alt="Więcej"/>
        </button>
    </div>
</ng-template>

<!-- simple button -->
<ng-template #buttonTpl let-button="button">
    <div class="buttons-vertical" [class.take-space]="button.isVisible">
        <button
                class="main-button"
                [class.active]="button.isVisible"
                [class.inactive]="!playerService.isActiveMe && button.show_only_active_me"
                [class.anim]="button.group_name"
                [matTooltip]="button.tooltip"
                [matTooltipPosition]="isDialog ? 'below' : 'right'"
                [matTooltipShowDelay]="300"
                (click)="handleMenuButtonEvent(button)">
            <img [src]="button.icon | asset: 'ui/menu/left'"
                 alt="{{button.tooltip}}" />

            <ng-container *ngIf="button.customNotification as notification">
                <ng-template
                        *ngIf="notification.type === 'async' && (notification.observable | async)"
                        [ngTemplateOutlet]="notificationTpl"></ng-template>
            </ng-container>

            <div *ngIf="isDialog" class="button-name">
                {{ button.tooltip }}
            </div>
        </button>

        <ng-container *ngFor="let menuButton of button.group_buttons">
            <ng-container [ngTemplateOutlet]="buttonTpl" [ngTemplateOutletContext]="{ button: menuButton }"></ng-container>
        </ng-container>
    </div>
</ng-template>

<ng-template #notificationTpl>
    <i class="icon notification"></i>
</ng-template>
