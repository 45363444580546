<div class="global-dialog">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <ng-container *ngIf="collections.items.length">

    <div class="top-info">
      <p class="title">Kolekcje muzealne</p>
    </div>

    <!-- NAVIGATION CENTER -->
    <m-ui-navigation-center
      [hidePrev]="currentSlideIndex <= 0"
      [hideNext]="currentSlideIndex >= collections.pages.length - 1"
      (prevClicked)="prevSlide()"
      (nextClicked)="nextSlide()"
    ></m-ui-navigation-center>

    <swiper-container *ngIf="initSwiper" swiperElement [config]="config" #sliderMuseumCollection init="false">
      <swiper-slide *ngFor="let page of collections.pages;">
        <div class="collections">
          <div class="ow-dialog window-b primary loading-opacity"
               *ngFor="let collection of page; let idx = index"
               (click)="openMuseumCollectionDetails(collection, idx)"
               [class.gathered-all]="collection.obtained_items === collection.total_items && collection.obtained_items > 0"
               [class.gathered-some]="collection.obtained_items < collection.total_items && collection.obtained_items > 0"
          >
            <div class="worth">
              <img class='exposure' [src]="'exposure_value.png' | asset: 'parameters/small'">
              <span>{{collection.obtained_worth}}/{{collection.total_worth}}</span>
            </div>

            <div class="icon"
                 [style.background-image]="'url(' + (collection.museum_set.icon + '.png' | asset:'icons/big' ) + ')'"
            ></div>
            <div class="name">{{collection.museum_set.name}}</div>

            <div class="bottom">

              <div class="score-box">
                <img [src]="'artifacts.png' | asset: 'icons/basic'"/>
                <div class="score-container">
                  <div class="score">{{collection.obtained_items}}/{{collection.total_items}}</div>
                </div>
              </div>


              <div class="progressbar-container">
                <div class="box">
                  <div class="border"></div>
                  <ng-container *ngIf="collection.obtained_items">
                    <!-- FILL -->
                    <div
                      class="fill"
                      [style.width]="'calc(' + (collection.obtained_items / collection.total_items * 100 * 0.5) + 'px * var(--multiply))'"
                    >
                    </div>
                  </ng-container>
                </div>

                <img
                  *ngIf="collection.museum_set.completed_set_reward && collection.museum_set.completed_set_reward.has_received"
                  [src]="'icon-check.png' | asset: 'ui'"
                  class="check"
                />

                <!-- BUTTON -->
                <div class="progress-icon">
                  <img [src]="'chest_base_special_small.png' | asset: 'icons/basic'">
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
    <div class="bottom-info">
      <div class="icon">!</div>
      Odnajduj eksponaty, uzupełniaj kolekcje muzealne <br> i zwiększaj wartość całej ekspozycji
    </div>
  </ng-container>
</div>

