import {TileMenuCore} from '../../classes/tile-menu/core/TileMenuCore';
import {isDive} from '../../../game-gui/helpers/buildings.helper';
import {BoardTile} from '../../classes/custom/BoardTile.class';
import {getDiscoverPlaceType, getIslandFromWorld} from '../../utils/board.helper';
import {TILE_MENU_ATLAS} from '../../scenes-main/main.constants';
import {TREASURES_TILE_ARCHIVO, TREASURES_TILE_MENU_STYLES} from '../treasures.constants';
import {TreasuresTileState} from '../treasures.interfaces';
import {GAME_EVENTS} from '../../../constants';
import {Scene} from 'phaser';
import {Region} from '../../../interfaces/region.interface';

export class TreasureTileMenuClass extends TileMenuCore {
  text: Phaser.GameObjects.Text;
  scene: Scene & { regions: Region[]}

  generateAll() {
    if (isDive(this.targetTile.tileData.player_building.group_type)) {
      this.handleDiscoverPlaceType(this.targetTile);
    } else {
      super.generateAll();
    }
  }

  handleDiscoverPlaceType(tile: BoardTile) {
    this.y -= this.targetTile.baseSprite.height / 2;
    const targetIsland = getIslandFromWorld(tile.tileData.open_player_island_id, this.scene.regions);
    let placeType;
    if (targetIsland) {
      placeType = getDiscoverPlaceType(targetIsland);
    }

    const bgSprite = this.scene.add.sprite(0, 0, TILE_MENU_ATLAS, 'menu-bg.png');
    bgSprite.setScale(2, 2);
    this.add(bgSprite);
    this.alpha = 0;

    const text = `${tile.tileData.player_building.name.toUpperCase()}`;

    this.text = this.scene.add.text(0, -18, text, TREASURES_TILE_MENU_STYLES);
    this.text.setOrigin(0.5, 1);
    this.text.lineSpacing = -14;
    this.add(this.text);

    const text2 = this.scene.add.text(0, 5, 'TYP:', TREASURES_TILE_ARCHIVO);
    text2.setOrigin(0.5, 0.5);
    this.add(text2);

    const textPlaceType = this.scene.add.text(0, 20, placeType.name, TREASURES_TILE_MENU_STYLES);
    textPlaceType.setOrigin(0.5, 0);
    this.add(textPlaceType);

    const separator = this.scene.add.sprite(0, -15, TILE_MENU_ATLAS, 'menu-separator.png');
    separator.setOrigin(0.5, 0.5);
    separator.setScale(2, 2);
    this.add(separator);

    if ((tile.tileData as TreasuresTileState).inner_island_player_resource_summary) {
      const treasuresStatusText = this.scene.add.text(
        0,
        60,
        `WYŁOWIONE SKARBY: \n ${(tile.tileData as TreasuresTileState).inner_island_player_resource_summary.retrieved}/${(tile.tileData as TreasuresTileState).inner_island_player_resource_summary.amount}`,
        TREASURES_TILE_ARCHIVO
      );
      treasuresStatusText.setOrigin(0.5, 0);
      treasuresStatusText.lineSpacing = -14;
      this.add(treasuresStatusText);
    }


    const button = this.scene.add.image(0, 130, TILE_MENU_ATLAS, 'ic-magnifer.png');
    this.add(button);
    button.setOrigin(0.5, 0);
    button.setScale(2, 2);
    button.setInteractive({
      cursor: 'pointer',
    });
    button.on('pointerup', () => {
      // emit change map service
      this.myScene.gameService.globalService.globalEvents.emit({
        name: GAME_EVENTS.MAP_CHANGE,
        value: {
          name: 'discover',
          config: {
            configName: 'discover',
            islandId: tile.tileData.open_player_island_id,
            additionalData: {
              discoverType: placeType
            }
          }
        },
      });
    });

    this.scene.add.tween({
      targets: [this],
      alpha: 1,
      duration: 300,
      ease: Phaser.Math.Easing.Quadratic.Out
    });
  }
}
