import { Injectable } from '@angular/core';
import { ApiService } from '../../../../../../../core/providers/api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiMuseumCollectionService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  getPlayerSets() {
    return this.apiService.get('museum/player-sets');
  }

  getPlayerSet(set_id) {
    return this.apiService.get(`museum/set/${set_id}`);
  }

  getMuseumReward(set_id) {
    return this.apiService.post(`museum/set/${set_id}/reward`);
  }
}
