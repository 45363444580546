import { ChangeDetectionStrategy, Component, NgZone, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";

import { GlobalEvent } from "../../../../../core/interfaces/shared.interfaces";
import { GlobalService } from "../../../../../core/providers/global.service";
import { GAME_EVENTS } from "../../../constants";
import { GameFeaturesMap } from "../../../constants/game-features";
import { GameService } from "../../../services/game.service";
import { MyGame } from "../../classes/core/MyGame";
import { customGameConfig } from "../../config/custom-game.config";
import { MyGameConfig } from "../../interfaces/my-game-config";
import { CustomBootScene } from "../../scenes-main/custom/CustomBootScene";
import { CustomWorldBootScene } from "../../scenes-world/custom/CustomWorld.boot.scene";
import { prepareGameConfig } from "../../utils/game-config.utils";

@Component({
  selector: "app-game-engine-p3",
  templateUrl: "./game-engine-p3.component.html",

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GameEngineP3Component implements OnInit, OnDestroy {
  public game: MyGame;
  private globalEvents: Subscription;
  private gameConfig: any;

  public constructor(
    private gameService: GameService,
    private ngZone: NgZone,
    public globalService: GlobalService
  ) {}

  ngOnInit() {
    const baseGameConfig: MyGameConfig = {
      parent: "game",
      type: GameFeaturesMap.forceCanvas ? Phaser.CANVAS : Phaser.AUTO,
      scene: [CustomBootScene, CustomWorldBootScene],
      gameService: this.gameService,
      render: {
        roundPixels: true, // smooths the edges on pixel art like sprites
        antialias: true,
        pixelArt: false, // makes pixel art images sharp if resized
        clearBeforeRender: false, // clear the cnavas on render, redundant when we have one big image on the BG
        premultipliedAlpha: true, // better perf if the graphics has transparent fields
        powerPreference: "high-performance"
      },
      disablePreFX: true, // disable in-game FX effects
      disablePostFX: true, // disable in-game fx effects
      fps: {
        target: 60, // phaser will try to reach this fps amount
        limit: 75, // this is maximum which can't be exceeded
        smoothStep: true, // makes moving around the map smooth
        forceSetTimeOut: false, // changes how it reacts to the 75, 120, 144hz screens
      },
      // loader: {
      //   // @ts-ignore
      //   imageLoadType: "HTMLImageElement",
      //   crossOrigin: "anonymous",
      //   async: true
      // },
      images: {
        default: "assets/phaser/default.png",
        missing: "assets/phaser/missing.png",
        white: "assets/phaser/white.png",
      },
      audio: {
        disableWebAudio: true,
        noAudio: true
      },
      scale: {
        mode: Phaser.Scale.NONE,
        width: window.innerWidth * window.devicePixelRatio,
        height: window.innerHeight * window.devicePixelRatio,
        zoom: 1 / window.devicePixelRatio,
        fullscreenTarget: document.querySelector("body"),
      },
      banner: false,
    };

    this.gameConfig = prepareGameConfig(baseGameConfig, customGameConfig);
    this.ngZone.runOutsideAngular(() => {
      this.game = this.gameService.initGame(this.gameConfig, false);
    });
    this.globalEvents = this.gameService.globalService.globalEvents.subscribe(this.handleGlobalEvent.bind(this));
  }

  handleGlobalEvent(event: GlobalEvent) {
    switch (event.name) {
      case GAME_EVENTS.START_SCENE:
        // made in #29214; probably redundant
        // this.gameService.guiService.isSplashShow.next(true);

        this.gameService.game.currentScene.destroyScene();
        // made in #29215; i'm not deleting it for now cuz it may be problematic and I have to test it :) ~ Hugo
        // this.gameService.store.dispatch(gameResetBoard());
        // this.gameService.store.dispatch(gameSetCurrentScene(null));
        //
        // if (!this.gameConfig.disablePrepareGameData) {
        //   this.gameService.prepareGameData(this.gameService.playerService.player.id, event.data ? event.data.playerIslandId : null);
        // }

        if (!event.data) {
          event.data = {
            islandGroupType: "main",
          };
        }

        this.gameService.game.scene.start(event.value, event.data);
        break;
    }
  }

  ngOnDestroy(): void {
    this.globalEvents.unsubscribe();
    this.gameService.game.currentScene.scene.stop();
    this.gameService.game.currentScene.destroyScene();
    this.gameService.destroyAllScenes();
    this.gameService.game.destroy(true, false);
    this.gameService.game = null;
  }
}
