import {Component, HostBinding, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { Store } from "@ngrx/store";

import { OwInject } from "../../../../../../../../core/decorators/ow-inject.decorator";
import { AssetsService } from "../../../../../../../../core/providers/assets.service";
import { unsubscribeObject } from "../../../../../../../../core/utility/unsubscribe-array";
import { AppState } from "../../../../../../../../store/state";
import { PlayerService } from "../../../../../../../player/providers/player.service";
import { DialogService } from "../../../../../../../shared/providers/dialog.service";
import { AbstractBusinessCards } from "../../../../abstract/custom/abstract-business-cards.component";
import { BasicActionsComponent } from "../basic-actions/basic-actions.component";
import { selectGameCurrentScene } from '../../../../../../../../store/game/selectors';

@Component({
  selector: "app-business-cards",
  templateUrl: "./business-cards.component.html",
})
export class BusinessCardsComponent extends AbstractBusinessCards implements OnInit, OnDestroy {
  @OwInject(Store) store: Store<AppState>;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(PlayerService) playerService: PlayerService;
  @OwInject(AssetsService) assetsService: AssetsService;
  @HostBinding("class.visible") get isVisible() {
    return this.visible;
  }
  basicActionsComponent = BasicActionsComponent;

  ngOnInit() {
    this.subscribeGlobalEvents();

    const tasksSetup: { month: number; year: number } = this.playerService.player["tasks_setup"];
    this.startDate = {
      month: tasksSetup.month - 1,
      year: tasksSetup.year,
    };

    const playerTasksPagination: { month: number; year: number } = this.playerService.player["player_tasks_pagination"];
    this.endDate = {
      month: playerTasksPagination.month - 1,
      year: playerTasksPagination.year,
    };
    this.businessRanksService.fetchBussinesRanks();
    this.getTasks();

    // hide whole module if this is discover scene; some assets are on vision line of cards
    this.subs['sceneChange'] = this.store.select(selectGameCurrentScene).subscribe(res => {
      this.visible = res !== 'discover';
    })
  }

  ngOnDestroy() {
    unsubscribeObject(this.subs);
  }
}
