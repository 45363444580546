import { BoardTile } from '../../classes/custom/BoardTile.class';
import { GameService } from '../../../services/game.service';
import { BoardViewMode, BUILDING_NAME_FONT_STYLES } from '../../../constants';
import { MyScene } from '../../classes/core/MyScene';
import { BoardContainer } from '../../classes/core/BoardContainer';
import moment from 'moment';
import Tween = Phaser.Tweens.Tween;

export class Ship extends BoardContainer {

  shipImage: Phaser.GameObjects.Image;
  status: ShipStatus;
  homeTile: BoardTile;
  targetTile: BoardTile;
  myScene: MyScene;

  intervalRef: null | ReturnType<typeof setTimeout> = null;

  anchoredTweens: Tween[] = [];
  gameService: GameService;
  textSprite: Phaser.GameObjects.Text;

  constructor(scene: MyScene, homeTile: BoardTile) {
    super({
      scene,
      x: homeTile.x + 100,
      y: homeTile.y,
      viewMode: BoardViewMode.Ortogonal
    });
    this.myScene = scene;
    this.homeTile = homeTile;
    this.gameService = scene.gameService;
    scene.add.existing(this);

    this.shipImage = this.scene.add.image(0, 0, 'map-atlas', 'ship.png');
    this.shipImage.setOrigin(0.5, 1);
    this.setDepth(homeTile.depth + 1);
    this.add(this.shipImage);

    this.textSprite = this.scene.add.text(-50, -230, '', {
      ...BUILDING_NAME_FONT_STYLES,
      fontSize: '32px',
      color: '#FFFFFF'
    });
    this.textSprite.alpha = 0;
    this.textSprite.setStroke('#000', 5);
    this.add(this.textSprite);

    this.setStatus(ShipStatus.Anchored);
    const tempKey = this.scene.input.keyboard.addKey('r');
    tempKey.on('up', () => {
      this.drawTile();
    });
  }

  drawTile() {
    const tile = this.myScene.board.boardTiles[Math.floor(Math.random() * this.myScene.board.boardTiles.length)];
    if (location.search.includes('mock-treasures')) {
      tile.tileData.player_building.group = 'dive_sd';
    }
    if (tile.hasBuilding && tile.playerBuildingData.group.indexOf('dive_') > -1) {
      this.sendToTile(tile);
    } else {
      this.drawTile();
    }
  }

  setStatus(status: ShipStatus) {
    this.status = status;

    switch (this.status) {
      case ShipStatus.Anchored:
        this.anchoredTweens.push(
          this.scene.add.tween({
            targets: [this.shipImage],
            y: this.shipImage.y - 5,
            duration: 2000,
            ease: Phaser.Math.Easing.Quadratic.InOut,
            repeat: -1,
            yoyo: true,
            delay: Math.random() * 2000
          })
        );
        this.anchoredTweens.push(
          this.scene.add.tween({
            targets: [this.shipImage],
            angle: this.shipImage.angle - 2,
            duration: 2000,
            ease: Phaser.Math.Easing.Quadratic.InOut,
            repeat: -1,
            yoyo: true,
            delay: Math.random() * 2000
          })
        );
        break;

      case ShipStatus.Sailing:
        this.anchoredTweens.filter(tween => {
          tween.stop();
        });
        break;
    }
  }

  sendHome() {
    // prevent initial reload
    if (this.homeTile.x + 100 !== this.x || this.homeTile.y !== this.y) {
      this.sendToTile(this.homeTile);
    }
  }

  startTimer() {
    this.textSprite.alpha = 1;
    this.intervalRef = setInterval(() => {
      if (this.scene && this.targetTile.tileData.inner_island_player_building_in_progress) {
        const playerRealTime = this.gameService.playerService.getRealTime();
        const secondsLeft = moment(this.targetTile.tileData.inner_island_player_building_in_progress.built_at).diff(moment(playerRealTime), 'seconds');
        const time = this.gameService.hoursPipe.transform(secondsLeft, 'seconds');
        this.textSprite.setText(`${time}`);
      } else {
        clearInterval(this.intervalRef);
      }
    }, 1000);
  }

  sendToTile(tile: BoardTile) {
    this.targetTile = tile;
    this.textSprite.alpha = 0;
    this.scene.add.tween({
      targets: [this],
      alpha: 0,
      ease: Phaser.Math.Easing.Quadratic.In,
      duration: 300,
      onComplete: () => {
        this.x = this.targetTile.x + 200;
        this.y = this.targetTile.y;
        this.setDepth(this.targetTile.depth + 1);
        this.scene.add.tween({
          targets: [this],
          alpha: 1,
          duration: 300,
          ease: Phaser.Math.Easing.Quadratic.Out,
        });
      }
    });

    if (!this.isHomeTile() && !this.targetTile.isBuildInProgress()) {
      this.startTimer();
    }
  }

  isHomeTile() {
    return this.targetTile.tileData.player_tile_id === this.homeTile.tileData.player_tile_id;
  }
}

export enum ShipStatus {
  Sailing,
  Anchored
}
