<div class="global-dialog ow-dialog window-a primary">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TOP BAR BASE -->
  <ng-container *ngIf="artefact">

    <!-- TOP BAR BASE -->
    <div class="top-bar">
      <div class="title" [innerHTML]="collectionName"></div>
    </div>

    <!-- ARTEFACT NAME -->
    <div class="title" [innerHTML]="artefact.name"></div>

    <!-- ARTEFACT ICON -->
    <div class="artefact-icon-container">
      <img [src]="artefact.iconUrlBig"/>
    </div>

    <!-- ARTEFACT DESCRIPTION -->
    <div class="artefact-desc" [innerHTML]="artefact.description"></div>

    <!-- SEPARATOR -->
    <m-ui-separator></m-ui-separator>

    <!-- ARTEFACT PARAMETER -->
    <div class="parameter">
      Wartość muzealna

      <div class="worth">
        <div class="icon">
          <img [src]="'exposure_value.png' | asset: 'parameters/big'"/>
        </div>
        <span class="value">
          {{ artefact['worth'] }}
        </span>
      </div>
    </div>
  </ng-container>
</div>
