import {ChangeDetectorRef, ElementRef, Injectable, ViewChild} from '@angular/core';
import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { Collection } from '../../interfaces/custom/museum-collection.interface';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { ApiMuseumCollectionService } from '../../api/custom/services/api-museum-collection.service';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { EventEmitterDialogsService } from '../../../../../../core/services/core/event-emitter-dialogs.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { generateEachPages } from '../../../../../shared/helpers/generate-pages.helper';
import { EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM } from '../../consts/custom/event-dialogs/event-names.const';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Swiper, SwiperOptions} from 'swiper/types';

@Injectable()
export abstract class AbstractMuseumCollectionListComponent extends AbstractInjectBaseComponent {
  @OwInject(ApiMuseumCollectionService) apiMuseumCollectionService: ApiMuseumCollectionService;
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(EventEmitterDialogsService) eventEmitterDialogsService: EventEmitterDialogsService;
  @OwInject(ChangeDetectorRef) changeDetectorRef: ChangeDetectorRef;
  @ViewChild('sliderMuseumCollection', {static: false}) sliderMuseumCollection: ElementRef | undefined;
  @OwInject(MAT_DIALOG_DATA) data: { isOpenByCollectionsList?: boolean, index?: number };
  config: SwiperOptions = {
    slidesPerView: 1,
    initialSlide: 0,
    centeredSlides: false,
  }
  initSwiper = false;
  swiper: Swiper = null;

  STOCK_VIEW = STOCK_VIEW;
  activeCollection: Collection;
  collectionsWorth: number;
  sliderActiveIndex;
  currentSlideIndex = 0;
  collections = {
    config: {
      itemPerPage: 3
    },
    pages: [],
    items: [],
  };

  getCollectionList() {
    this.apiMuseumCollectionService.getPlayerSets()
      .subscribe(
        (resp: Collection[]) => {
          this.collections.items = resp;
          this.collections.pages = [];
          this.generatePages();
          this.afterCollectionList();
          this.sumCollectionsWorth();
          this.setSwiper();
        });
  }

  sumCollectionsWorth() {
    const collectionsWorth = this.collections.items.map(c => c.total_worth).reduce((a, b) => {
      return a + b;
    });

    const collectionsPrizeWorth = this.collections.items.map((c: Collection) => c.museum_set.worth).reduce((a, b) => {
      return a + b;
    });

    this.collectionsWorth = collectionsWorth + collectionsPrizeWorth;
  }

  generatePages() {
    this.collections = generateEachPages(this.collections);
  }

  openMuseumCollectionDetails(collection, idx) {
    this.dialogService.closeActive();
    setTimeout(() => {
      this.eventEmitterDialogsService.emitter.emit({
        name: EVENT_DIALOGS_NAMES_MUSEUM_COLLECTION_CUSTOM.MUSEUM_COLLECTION_DETAILS,
        config: {
          data: {
            collection: collection,
            index: idx,
          }
        }
      });
    });
  }

  afterCollectionList() {
    if (this.data.isOpenByCollectionsList) {
      this.setActivePage(this.data.index);
    } else {
      this.sliderActiveIndex = 0;
    }
  }

  setActivePage(index: number) {
    this.sliderActiveIndex = Math.floor(index / 3);
  }

  setSwiper() {
    this.initSwiper = false;
    this.swiper = null;
    this.changeDetectorRef.detectChanges();
    this.initSwiper = true;
    this.changeDetectorRef.detectChanges();
    if (this.sliderMuseumCollection?.nativeElement) {
      this.swiper = this.sliderMuseumCollection.nativeElement.swiper;
      this.swiper.on("slideChange", swiper => {
        this.changeDetectorRef.detectChanges();
      });
    }
  }

  prevSlide() {
    this.swiper.slidePrev();
    this.currentSlideIndex = this.swiper.activeIndex;
  }

  nextSlide() {
    this.swiper.slideNext();
    this.currentSlideIndex = this.swiper.activeIndex;
  }
}
