import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import moment from "moment";
import { of, switchMap } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { omitErrorResponseHelper } from "../../core/helpers/omit-error-response.helper";
import { BoardService } from "../../modules/game/services/board.service";
import { GameService } from "../../modules/game/services/game.service";
import { AppState } from "../state";
import { userNullAction } from "../user/actions";
import {
  gameFetchBoard,
  gameFetchBoardCacheSuccess,
  gameFetchBoardSuccess,
  gameSetCurrentPlayerIslandId,
} from "./actions";
import { BoardTileState } from "./interfaces/board-tile.state";
import { selectGameBoardStatuses } from "./selectors";

@Injectable()
export class GameEffects {
  constructor(
    private actions$: Actions,
    private boardService: BoardService,
    private gameService: GameService,
    private store: Store<AppState>
  ) {}

  $fetchBoard = createEffect(() =>
    this.actions$.pipe(
      ofType(gameFetchBoard),
      concatLatestFrom(() => this.store.select(selectGameBoardStatuses)),
      switchMap((action, index) => {
        const payload = action[0];
        const gameStatuses = action[1];
        const boardStatus = gameStatuses[payload.playerIslandId];
        let boardIsValid = false;

        if (boardStatus) {
          boardIsValid = moment(boardStatus.date).isBefore(moment().add(15, "minutes"));
        }
        if (boardIsValid) {
          this.store.dispatch(gameFetchBoardCacheSuccess({playerIslandId: payload.playerIslandId}));
          return [];
        } else {
          return this.fetchBoard(payload.playerId, payload.playerIslandId);
        }
      })
    )
  );

  fetchBoard(playerId: number, playerIslandId?: number) {
    return this.boardService.getBoard(playerId, playerIslandId).pipe(
      tap((boardData: BoardTileState[]) => {
        if (!playerIslandId && boardData && boardData.length > 0) {
          playerIslandId = boardData[0].player_island_id;
        }
        this.gameService.lastPlayerIslandId = playerIslandId;

        this.store.dispatch(gameSetCurrentPlayerIslandId({ playerIslandId: playerIslandId || null }));
      }),
      map((boardData: BoardTileState[]) => {
        return gameFetchBoardSuccess({ boardTiles: boardData });
      }),
      catchError((error: any) => {
        omitErrorResponseHelper(error);
        return of(userNullAction());
      })
    );
  }
}
