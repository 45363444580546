import { DISCOVER_MAIN_SCENE } from '../discover.constants';
import { DiscoverPlaceTypes } from '../../../constants';
import {BasicMainScene} from '../../scenes-basic/core/Basic.main.scene';
import {DiscoverBoard} from '../overrides/DiscoverBoard.class';

export class DiscoverMainScene extends BasicMainScene {
  configKeyName = "discover";
  map: Phaser.Tilemaps.Tilemap;
  depthLayers: any[] = [];

  placeType = {
    type: DiscoverPlaceTypes.Deep,
    version: "a",
  };

  waveStart: any;

  constructor() {
    super({
      key: DISCOVER_MAIN_SCENE,
    });
  }

  init(data?) {
    this.passedData = data;
    this.tiledMapKey = data.tiledMapKey;
    this.placeType = this.passedData.discoverType;
    this.setCurrentScene();
  }

  preload(): void {
    super.preload();

    const tilemap = this.cache.tilemap.get(`discover-map-${this.passedData.discoverType.type}`);
    const textureKeys = this.textures.getTextureKeys();
    tilemap.data.tilesets.forEach(tileset =>
      Object.keys(tileset.tiles).forEach((tileKey, index) => {
        const key = `object-${tileset.firstgid + index}`;
        if (!textureKeys.includes(key)) {
          this.load.image(
            key,
            this.gameService.assetsService.getAssetPath(
              `map-discover/${this.placeType.type}/${tileset.tiles[tileKey].image}`,
              "DiscoverMain image load"
            )
          );
        }
      })
    );
  }

  createTiledMap() {
    this.map = this.add.tilemap(`discover-map-${this.passedData.discoverType.type}`);

    const tilemap = this.cache.tilemap.get(`discover-map-${this.passedData.discoverType.type}`);
    tilemap.data.tilesets.forEach(tileset => {
      this.map.addTilesetImage(tileset.image, tileset.image);
    });

    this.depthLayers = [];
    tilemap.data.layers
      .filter(layer => layer.name.indexOf(`-${this.placeType.version}`) > -1)
      .forEach((layer, index) => {
        if (layer.type === "objectgroup") {
          const group = this.add.group();

          layer.objects.forEach(object => {
            const sprite = this.add.image(object.x, object.y, `object-${object.gid}`, null);
            sprite.setDepth(index);
            sprite.setOrigin(0, 1);

            const flipX =
              object.properties && object.properties.find(property => property.name === "flip-x" && property.value);
            if (flipX) {
              sprite.setScale(-1, 1);
              sprite.setOrigin(1, 1);
            }

            const flipY =
              object.properties && object.properties.find(property => property.name === "flip-y" && property.value);

            if (flipY) {
              sprite.setScale(sprite.scaleX, -1);
              sprite.setOrigin(1, 0);
            }

            group.add(sprite);
          });
          this.depthLayers.push(group);
        }
      });

    this.depthLayers.reverse();

    this.cameras.main.roundPixels = true;

    super.createTiledMap();
  }

  get BoardClass() {
    return DiscoverBoard;
  }
  
  destroyScene() {
    console.log('destroy scene');
    this.depthLayers.forEach(group => {
      group.clear(true, true);
      group = null;
    });
    super.destroyScene();
  }
}
