import * as R from "ramda";

import { CARD_TEMPLATE } from "../../consts/custom/card-template.const";
import { Progress } from "../../interfaces/custom/progress.interface";
import { Scope } from "../../interfaces/custom/scope.interface";
import { Task } from "../../interfaces/custom/task.interface";

export function setProgressbarHelper(task: Task): Progress {
  function setValuePercentage(value: number): string {
    if (task.task_definition.value_type === 1 && task.card_template === CARD_TEMPLATE.S2) {
      const val = Math.floor(parseFloat(((value / +task.progressive_currency_prizes[0]?.max_realization_scope) * 100).toFixed(8))) + "%";
      return val;
    } else {
      return Math.floor(parseFloat((value * 100).toFixed(8))) + "%";
    }
  }

  const progress: Progress = {
    from: null,
    to: null,
    valuePercentage: null,
    label: null,
    title: null,
    classes: [],
  };

  // const valuePercetage = task.task_definition.hide_realization ? task.performance / task.target : task.realization;
  let valuePercetage = task.performance;

  if (task.card_template === CARD_TEMPLATE.S2 && task.performance === null) {
    progress.title = "Zadanie w trakcie parametryzacji";
  } else if (task.card_template === CARD_TEMPLATE.S1 && task.target === null) {
    progress.title = "Zadanie w trakcie parametryzacji";
  }
  if (task.card_template === CARD_TEMPLATE.S1) {
    valuePercetage = task.realization;
    progress.valuePercentage = setValuePercentage(valuePercetage);
    progress.title = "Twój aktualny poziom realizacji:";
    progress.label = progress.valuePercentage;
  }
  if (task.card_template === CARD_TEMPLATE.S2 || task.card_template === CARD_TEMPLATE.S3) {
    progress.valuePercentage = setValuePercentage(valuePercetage);
    progress.title = "Twój aktualny poziom realizacji:";
    progress.label = progress.valuePercentage;
  }

  return progress;
}
