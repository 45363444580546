import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChestsComponent } from '../../../base/custom/dialogs/chests/chests.component';
import { SharedUiMobileModule } from '../../../../shared-ui/mobile/shared-ui-mobile.module';
import { SharedModule } from '../../../../../../shared/shared.module';
import {OwPerfectScrollbarModule} from '@oskarwegner/ow-perfect-scrollbar';

@NgModule({
  declarations: [ChestsComponent],
  imports: [CommonModule, SharedUiMobileModule, SharedModule, OwPerfectScrollbarModule],
})
export class ChestsModule {}
