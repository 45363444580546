<div class="global-dialog ow-dialog window-a primary no-transparent" id="chests-dialog">
  <loading></loading>

  <!-- DIALOG EXIT -->
  <m-ui-close-button></m-ui-close-button>

  <!-- BACK BUTTON -->
  <m-ui-back-button></m-ui-back-button>

  <!-- TOP BAR BASE -->
  <div class="top-bar">
    <!-- BUILDING NAME -->
    <div class="title">
      Magazyn skrzyń
    </div>
  </div>

  <!-- CHESTS -->
  <div class="container-background loading-opacity">

    <!-- NO CHESTS -->
    <div class="empty-chests" *ngIf="chests && chests.length == 0">
      Nie masz jeszcze skrzyń. <br/> <br/> Odnajdziesz je w łowiskach i wykopaliskach.
    </div>

    <ng-container *ngIf="chests && chests.length > 0">
      <!-- ARROW SCROLL -->
      <ng-container *ngIf="chests.length">
        <i
          class="fas fa-angle-up up arrow-pagination"
          *ngIf="scrollTopChests > 0"
          (click)="scrollUp(1)"
        ></i>

        <i
          class="fas fa-angle-down down arrow-pagination"
          *ngIf="chests.length - 1 > 6 && !scrollChestsReachEnd"
          (click)="scrollDown(1)"
        ></i>
      </ng-container>

      <!-- API CHESTS -->
      <ow-perfect-scrollbar scrollGradient #perfectScrollbarChests (psScrollY)="scrollYChests($event)">
        <div
          class="chest"
          *ngFor="let chest of chests"
          (click)="changeChest(chest)"
          [style.background-image]="'url(' + (chest.icon + '.png' | asset:('chest/' + (chest.status == CHEST_STATUS.CLOSED ? 'close' : 'open'))) + ')'"
          [class.active]="chest.player_chest_id == activeChest?.player_chest_id"
          [matTooltip]="chest.name"
          matTooltipPosition="below"
          matTooltipShowDelay="300"
        >
        </div>
      </ow-perfect-scrollbar>
    </ng-container>
  </div>

  <!-- CURRENT CHEST DETAILS -->
  <div class="container-chest-details">
    <ng-container *ngIf="activeChest">

      <!-- DESCRIPTION -->
      <div
        class="description"
        *ngIf="activeChest.status == CHEST_STATUS.CLOSED"
        [innerHTML]="activeChest.description"
      ></div>

      <!-- REWARDS COUNT -->
      <div
        class="rewards-count"
        *ngIf="activeChest.status == CHEST_STATUS.OPENED || activeChest.status == CHEST_STATUS.USED"
        [class.no-rewards]="activeChest.rewards_count == 0 || null"
      >
        <ng-container *ngIf="activeChest.rewards_count as rewardsCount; else emptyChestTpl">
          W skrzyni znaleziono {{ rewardsCount }}
          {{ rewardsCount == 1 ? 'cenny przedmiot' : '' }}
          {{ (rewardsCount >= 2 && rewardsCount <= 4) ? 'cenne przedmioty' : '' }}
          {{ rewardsCount >= 5 ? 'cennych przedmiotów' : '' }}
        </ng-container>
        <ng-template #emptyChestTpl>
          W tej skrzyni nic nie było.
        </ng-template>
      </div>

      <!-- NAME -->
      <span class="name">
        {{ activeChest.name }}
      </span>
    </ng-container>

    <!-- CHEST CONTAINER -->
    <div class="container-chest" [class.disabled]="!activeChest">

      <!-- CHEST DISABLED -->
      <ng-container *ngIf="!activeChest; else activeChestTpl">
        <span>Wybierz skrzynię</span>
      </ng-container>

      <ng-template #activeChestTpl>

        <!-- CHEST CLOSED -->
        <div *ngIf="activeChest.status == CHEST_STATUS.CLOSED" class="chest-closed"
             [class.special-closed]="activeChest.icon == 'chest_special'">

          <!-- ERROR OPEN -->
          <span class="error" *ngIf="errorOpen">{{ errorOpen }}</span>

          <!-- ALLOW OPEN -->
          <span class="allow-open" *ngIf="allowOpenError" [innerHTML]="allowOpenError"></span>

          <!-- NOT REQUIRE KEY -->
          <span *ngIf="activeChest.product_open_prices.length === 0">
            Skrzynia nie wymaga klucza
          </span>

          <!-- DECORATION -->
          <div
            id="decorator"
            class="decoration"
            [style.background]="'url(' + (activeChest.decoration_icon + '.png' | asset:'chest/decoration' ) + ')'"
          ></div>
        </div>

        <!-- CHEST OPENED & USED -->
        <div *ngIf="activeChest.status == CHEST_STATUS.OPENED || activeChest.status == CHEST_STATUS.USED"
             class="chest-used">
          <ng-container *ngIf="activeChest.icon != 'chest_special'; else chestSpecialTpl">
            <div class="chest-top-closed animate-closed" [class.force-hide]="skipClaimAnimation"></div>
            <div
              class="chest-top-opened animate-opened"
              [class.force-show]="skipClaimAnimation"
              [class.empty]="activeChest.rewards_count == 0 || null"
            ></div>
            <div class="chest-bottom" [class.special-bottom]="activeChest.icon == 'chest_special'"></div>
          </ng-container>

          <ng-template #chestSpecialTpl>
            <div class="special-top-closed animate-closed" [class.force-hide]="skipClaimAnimation"></div>
            <div class="special-top-opened animate-opened" [class.force-show]="skipClaimAnimation"></div>
            <div class="special-bottom"></div>
          </ng-template>

          <!-- PRIZES -->
          <div class="prizes" *ngIf="activeChest.status == CHEST_STATUS.USED" [class.opacity-0]="this.opacityOpen">
            <div class="prize-relative" [class.shrink]="(activeChest.currency_rewards?.length + activeChest.product_rewards?.length) > 5">
              <!-- CURRENCIES -->
              <m-ui-currency
                *ngFor="let currency of activeChest.currency_rewards"
                [item]="currency"
                [stockView]="STOCK_VIEW.C"
                [lack]="false"
              ></m-ui-currency>

              <!-- PRODUCTS -->
              <m-ui-product
                *ngFor="let product of activeChest.product_rewards"
                [item]="product"
                [openStorage]="product"
                [stockView]="STOCK_VIEW.C"
                [lack]="false"
              ></m-ui-product>
            </div>
          </div>

          <!-- DECORATION -->
          <div
            class="decoration"
            [style.background]="'url(' + (activeChest.decoration_icon + '.png' | asset:'chest/decoration' ) + ')'"
          ></div>
        </div>
      </ng-template>
    </div>
  </div>

  <!-- KEYS -->
  <div class="container-background loading-opacity">

    <!-- ARROW SLIDER -->
    <ng-container *ngIf="keys.length">
      <i
        class="fas fa-angle-up up arrow-pagination"
        *ngIf="scrollTopKeys > 0"
        (click)="scrollUp(2)"
      ></i>

      <i
        class="fas fa-angle-down down arrow-pagination"
        *ngIf="keys.length - 1 > 6 && !scrollKeysReachEnd"
        (click)="scrollDown(2)"
      ></i>
    </ng-container>

    <!-- NO KEYS -->
    <div class="empty-keys" *ngIf="keysDefinitions && keysDefinitions.length == 0">
      Nie masz jeszcze kluczy. <br/> <br/> Odnajdziesz je w łowiskach i wykopaliskach.
    </div>

    <ng-container *ngIf="keysDefinitions && keysDefinitions.length">
      <ow-perfect-scrollbar scrollGradient #perfectScrollbarKeys (psScrollY)="scrollYKeys($event)">
        <div
          class="key"
          *ngFor="let key of keys"
          [style.background-image]="'url(' + (key.icon + '.png' | asset:'products/big') + ')'"
          [matTooltip]="key.name"
          matTooltipPosition="below"
          matTooltipShowDelay="300"
        >
          <div class="key-amount">
            {{ key.balance }}
          </div>
        </div>
      </ow-perfect-scrollbar>
    </ng-container>
  </div>

  <!-- BUTTON -->
  <div class="bottom-bar" *ngIf="activeChest && activeChest.status != CHEST_STATUS.USED">
    <div class="bar-button base">
      <button
        class="base secondary"
        [disabled]="!allowOpenChest || opening"
        (click)="openAndClaimChest()"
        *ngIf="!activeChest || (activeChest && activeChest.status == CHEST_STATUS.CLOSED)"
      >
        Otwórz skrzynię
      </button>
    </div>
  </div>
</div>
