import { Injectable } from "@angular/core";

import { BusinessEventDialogsService } from "../../../modules/game/game-ui/business/services/custom/event-dialogs.service";
import { RankingsEventDialogsService } from "../../../modules/game/game-ui/rankings/services/custom/event-dialogs.service";
import {ChestsEventDialogsService} from '../../../modules/game/game-ui/chests/services/custom/event-dialogs.service';
import {MuseumCollectionEventDialogsService} from '../../../modules/game/game-ui/museum-collection/services/custom/event-dialogs.service';

@Injectable({
  providedIn: "root",
})
export class RegisterCustomEventDialogsService {
  constructor(
    private rankingsEventDialogsService: RankingsEventDialogsService,
    private businessEventDialogsService: BusinessEventDialogsService,
    private chestsEventDialogsService: ChestsEventDialogsService,
    private museumCollectionEventDialogsService: MuseumCollectionEventDialogsService,
  ) {}
}
