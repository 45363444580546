export class Cloud extends Phaser.GameObjects.Image {
  initPosition: {
    posX: number,
    posY: number,
    posZ: number
  };
  windSpeed;
  shadow;

  constructor(scene, posX, posY, posZ, windSpeed, key, shadow?, rotation?) {
    super(scene, posX, posY, key);

    this.windSpeed = windSpeed;
    this.initPosition = {
      posX, posY, posZ
    };

    this.rotation = rotation;
    this.setOrigin(0.5);
    this.setScale(2);
    if (shadow) {
      this.tint = 0x000000;
      this.setDepth(this.initPosition.posZ - 1);
      // this.setAlpha(0.7);
    } else {
      this.setDepth(this.initPosition.posZ);
    }
    this.scene.add.existing(this);

    this.scene.tweens.add({
      targets: this,
      x: this.initPosition.posX + 2000,
      y: this.initPosition.posY - 11200,
      duration: 105000,
      ease: 'Linear',
    });
  }
}

export interface CloudConfig {
  x: number;
  y: number;
  z: number;
}
