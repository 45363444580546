import { SceneConfig } from '../interfaces/scene-config.interface';
import { BASIC_SCENE_BASE_CONFIG } from '../scenes-basic/basic-scene.base-config';
import {clone} from 'ramda';

export const TREASURES_SCENE_CONFIG: SceneConfig | any = {
  ...clone(BASIC_SCENE_BASE_CONFIG),
  tiledStartOffset: {
    x: 0,
    y: 0
  },
  tiledJsonPrefix: null,
  backgroundImages: [
    {
      filename: 'bg2-l.jpg',
      key: 'map-treasures-l',
      displayWidth: 3600,
      displayHeight: 4050,
      x: 0,
      y: 0,
    },
    {
      filename: 'bg2-r.jpg',
      key: 'map-treasures-r',
      displayWidth: 3600,
      displayHeight: 4050,
      x: 3600,
      y: 0,
    },
  ],
  cameraBounds: {
    x: 0,
    y: 0,
    width: 7200,
    height: 4050,
  },
  mapDirectoryName: 'map-treasures',
  tiledMapFileName: null,
  mapAtlasDirectory: "map-promenade",
  islandGroupIdForBoard: 'B',
  boardStartOffset: {
    x: 3600,
    y: 0
  },
  other: {
    sceneZoom: {
      max: 0.9,
      min: 0.4,
    },
    sceneSize: {
      width: 7200,
      height: 4050,
    },
    startZoom: 0.5,
    gui_unlocks_button: 'treasure_map',
    configKeyName: "treasures"
  },
  configKeyName: "treasures",
  cameraStartPosition: {
    x: 3400,
    y: 1000
  },
  gui_unlocks_button: null,
  tileMenuAtlasDirectory: 'tile-menu',
  tileTooltipConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileTooltipConfig,
    maxZoomScaleValue: 1.5
  },
  tileMenuConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileMenuConfig,
    maxZoomScaleValue: 1.5
  },
  tileHoverConfig: {
    ...BASIC_SCENE_BASE_CONFIG.tileHoverConfig,
    maxZoomScaleValue: 2
  }
};
