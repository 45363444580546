import { utilityErrorPreloadAssetsMap } from "../../../../../store/utility/actions";
import { MAP_ATLAS_NAME } from "../../../constants";
import { GameFeaturesMap } from "../../../constants/game-features";
import { MyGame } from "../../classes/core/MyGame";
import { MyScene } from "../../classes/core/MyScene";
import { TILE_MENU_ATLAS } from "../../scenes-main/main.constants";
import { BASIC_MAIN_SCENE, BASIC_PRELOAD_SCENE } from "../basic.constants";
import { BASIC_SCENE_CONFIG } from "../basic-scene.config";
import {DISCOVER_MAIN_SCENE} from '../../scenes-discover/discover.constants';
import {PROMENADE_MAIN_SCENE} from '../../scenes-promenade/promenade.constants';
import {TREASURES_MAIN_SCENE} from '../../scenes-treasure/treasures.constants';

export class BasicPreloadScene extends MyScene {
  constructor(sceneClassConfig?: Phaser.Types.Scenes.SettingsConfig) {
    sceneClassConfig = sceneClassConfig ? sceneClassConfig : { key: BASIC_PRELOAD_SCENE };
    super(sceneClassConfig);
  }

  assetsToPreload: string[];

  init(data = {} as any) {
    this.assetsToPreload = data.assetsToPreload;
    this.passedData = data.passedData;
  }

  provideSceneConfig() {
    if (this.passedData && this.passedData.sceneConfigKey) {
      return BASIC_SCENE_CONFIG[this.passedData.sceneConfigKey];
    }
    return BASIC_SCENE_CONFIG["default"];
  }

  customPreload() {}

  preload(): void {
    super.preload();
    const sceneConfig = this.provideSceneConfig();
    this.sceneConfig = sceneConfig;
    this.gameService = (this.game as MyGame).gameService;

    this.load.on("loaderror", (fileObj: Phaser.Loader.File) => {
      if (fileObj.type === "image" && fileObj.percentComplete === -1 && fileObj.state === Phaser.Loader.FILE_PENDING) {
        this.gameService.store.dispatch(utilityErrorPreloadAssetsMap());
      }
    });

    this.customPreload();
    if (this.sceneConfig.tiledMapFileName) {
      this.loadTiledMapAssets();
    }

    this.load.image("construction", this.gameService.assetsService.getAssetPath("buildings/construction.png", 'construction image inside Basic.preload.scene'));

    if (sceneConfig.mapAtlasDirectory) {
      this.load.atlas(
        MAP_ATLAS_NAME,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.png`, 'map atlas.png inside Basic.preload.scene'),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.mapAtlasDirectory}/atlas/map-atlas.json`, 'map atlas.json inside Basic.preload.scene')
      );
    }

    if (sceneConfig.tileMenuAtlasDirectory) {
      this.load.atlas(
        TILE_MENU_ATLAS,
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.png`, 'tile-menu-atlas.png inside Basic.preload.scene'),
        this.gameService.assetsService.getAssetPath(`${sceneConfig.tileMenuAtlasDirectory}/tile-menu-atlas.json`, 'tile-menu-atlas.json inside Basic.preload.scene')
      );
    }

    if (GameFeaturesMap.sceneBgImages) {
      sceneConfig.backgroundImages.forEach(image => {
        this.load.image(
          image.key,
          this.gameService.assetsService.getAssetPath(`${sceneConfig.mapDirectoryName}/${image.filename}`, `backgroundImage dir: ${sceneConfig.mapDirectoryName} filename: ${image.filename} inside BasicPreloadScene`)
        );
      });
    }

    if (sceneConfig.layeredMapJson) {
      sceneConfig.layeredMapJson.forEach(el => {
        this.load.tilemapTiledJSON(
          el.key,
          this.gameService.assetsService.getAssetPath(el.path)
        )
      })
    }

    this.gameService = (this.sys.game as MyGame).gameService;

    this.load.on("progress", progress => {
      this.gameService.progressLoading = Math.round(100 * progress);
    });

    this.load.on("complete", progress => {});

    this.assetsToPreload.filter(Boolean).forEach(asset => {
      this.load.image(asset, asset);
    });

    const gl = this.game.renderer["gl"];
    if (gl) {
      console.log("MAX TEXTURE SIZE", gl.getParameter(gl.MAX_TEXTURE_SIZE));
    }

    const renderTypes = {
      1: "CANVAS",
      2: "WEBGL",
      3: "HEADLESS(testing)",
      4: "AUTO",
    };
    window["renderMode"] = {
      mode: this.game.config.renderType,
      text: renderTypes[this.game.config.renderType],
    };

    this.cameras.main.setBackgroundColor("#5065aa");
  }

  create(): void {
    if (this.sceneConfig.configKeyName === 'promenade') {
      this.scene.start(PROMENADE_MAIN_SCENE, this.passedData);
    }

    if (this.sceneConfig.configKeyName === 'treasures') {
      this.scene.start(TREASURES_MAIN_SCENE, this.passedData);
    }

    if (this.sceneConfig.configKeyName === 'discover') {
      this.scene.start(DISCOVER_MAIN_SCENE, this.passedData);
    }
  }

  loadTiledMapAssets() {
    const mapFilePath = this.gameService.assetsService.getAssetPath(
      `${this.sceneConfig.mapDirectoryName}/${this.sceneConfig.tiledMapFileName}.json`,
      'tileMapAssets inside BasicPreloadScene: ' + this.sceneConfig.mapDirectoryName + ' or ' + this.sceneConfig.tiledMapFileName
    );
    this.load.json(`${this.sceneConfig.mapDirectoryName}/${this.sceneConfig.tiledMapFileName}`, mapFilePath);
  }
}
