import { AbstractInjectBaseComponent } from '../../../../../../core/abstracts/abstract-inject-base.component';
import { Directive, ElementRef, Injectable, ViewChild } from '@angular/core';
import { OwInject } from '../../../../../../core/decorators/ow-inject.decorator';
import { DialogService } from '../../../../../shared/providers/dialog.service';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../../store/state';
import { ProductAmount, ProductBalance, ProductDetails, ProductDetailsBalance } from '../../../../../player/interfaces/product.interface';
import { ProductPlayerService } from '../../../../../player/providers/product-player.service';
import { Currency, CurrencyDetails } from '../../../../../../core/interfaces/currency';
import { CurrencyService } from '../../../../../../core/providers/currency.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiChestsService } from '../../api/custom/services/api-chests.service';
import { STOCK_VIEW } from '../../../shared-ui/mobile/consts/stock-view.const';
import { unsubscribeObject } from '../../../../../../core/utility/unsubscribe-array';
import gsap from 'gsap';
import {selectPlayer} from '../../../../../../store/player/selectors';

@Directive()
@Injectable()
export abstract class AbstractChestsComponent extends AbstractInjectBaseComponent {
  @OwInject(DialogService) dialogService: DialogService;
  @OwInject(Store) store: Store<AppState>;
  @OwInject(ProductPlayerService) productPlayerService: ProductPlayerService;
  @OwInject(ApiChestsService) apiChestsService: ApiChestsService;
  @OwInject(CurrencyService) currencyService: CurrencyService;
  @OwInject(MatDialogRef) matDialogRef: MatDialogRef<AbstractChestsComponent>;
  @OwInject(MAT_DIALOG_DATA) data: { showBackButton: boolean, playerChestId: number };
  @ViewChild('perfectScrollbarChests') perfectScrollbarChests: ElementRef;
  @ViewChild('perfectScrollbarKeys') perfectScrollbarKeys: ElementRef;

  scrollTopChests: number;
  scrollTopKeys: number;
  scrollChestsReachEnd: boolean;
  scrollKeysReachEnd: boolean;
  chestAfterOpenAndClaim;
  CATEGORY_ID = 11;
  keys: ProductDetailsBalance[] = [];
  chests;
  activeChest: ChestDetails;
  allowOpenChest: boolean;
  errorOpen: string;
  allowOpenError: string;
  keysDefinitions: ProductDetails[];
  opening: boolean;
  skipClaimAnimation: boolean;
  chestMapCount: Map<number, number> = new Map();
  STOCK_VIEW = STOCK_VIEW;
  noRewards: boolean;
  opacityOpen: boolean;
  subs = {
    player: null,
  };
  CHEST_STATUS = {
    OPENED: 1,
    CLOSED: 2,
    USED: 3,
  };

  clearAllowOpenChest() {
    this.allowOpenChest = false;
  }

  getProductsByCategory() {
    this.productPlayerService.productsPlayer({category: this.CATEGORY_ID})
      .subscribe((resp: ProductDetails[]) => {
        this.keysDefinitions = resp;
        unsubscribeObject(this.subs);
        this.subscribePlayer();
        this.playerChests();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  clearScrollTops() {
    this.scrollTopChests = 0;
    this.scrollTopKeys = 0;
  }

  scrollUp(type) {
    if (type === 1) {
      this.scrollToChests(this.scrollTopChests - 180);
    } else {
      this.scrollToKeys(this.scrollTopKeys - 180);
    }
  }

  scrollDown(type) {
    if (type === 1) {
      this.scrollToChests(this.scrollTopChests + 180);
    } else {
      this.scrollToKeys(this.scrollTopKeys + 180);
    }
  }

  scrollToChests(to: number) {
    if (this.perfectScrollbarChests && this.perfectScrollbarChests['directiveRef']) {
      this.perfectScrollbarChests['directiveRef'].scrollTo(null, to, 300);
    }
  }

  scrollToKeys(to: number) {
    if (this.perfectScrollbarKeys && this.perfectScrollbarKeys['directiveRef']) {
      this.perfectScrollbarKeys['directiveRef'].scrollTo(null, to, 300);
    }
  }

  scrollYChests(event) {
    this.scrollTopChests = event.target.scrollTop;
    this.scrollChestsReachEnd = (event.target.scrollHeight - event.target.clientHeight) === this.scrollTopChests;
  }

  scrollYKeys(event) {
    this.scrollTopKeys = event.target.scrollTop;
    this.scrollKeysReachEnd = (event.target.scrollHeight - event.target.clientHeight) === this.scrollTopKeys;
  }

  subscribePlayer() {
    this.subs.player = this.store
      .pipe(
        select(selectPlayer)
      )
      .subscribe((player) => {
        this.setKeys();
        this.checkReloadChests(player.product_balances);
      });
  }

  setKeys() {
    this.keys = this.productPlayerService.getProducts(this.keysDefinitions);
  }

  checkReloadChests(productBalances: ProductBalance[]) {
    const minimalChestProductId = 9000;
    let reload = false;

    productBalances.forEach((productBalance) => {
      this.chestMapCount.forEach((balance, productId) => {
        if (productBalance.product_id === productId && !reload) {
          reload = productBalance.balance !== balance;
        }
      });

      if (!reload && productBalance.product_id >= minimalChestProductId && !this.chestMapCount.has(productBalance.product_id)) {
        reload = true;
      }
    });

    if (reload) {
      this.playerChests();
    }
  }

  playerChests() {
    this.clearAllowOpenChest();

    this.apiChestsService.playerChests()
      .subscribe((resp: Chest[]) => {
        this.chests = <ChestDetailsProcess[]>resp;
        if (this.data.playerChestId) {
          const activeChest = this.chests.find((chest) => chest.player_chest_id === this.data.playerChestId);
          this.changeChest(activeChest);
          this.findActiveChest();
        }
        if (this.activeChest) {
          this.findActiveChest();
        }
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  findActiveChest() {
    if (this.perfectScrollbarChests) {
      setTimeout(() => {
        const el = this.perfectScrollbarChests['directiveRef'].elementRef.nativeElement.querySelector('.active');
        this.scrollToChests(el['offsetTop']);
      }, 200);
    }
  }

  openAndClaimChest() {
    this.opening = true;
    this.opacityOpen = true;

    this.apiChestsService.playerChestsIdOpenAndClaim(this.activeChest.player_chest_id)
      .subscribe((chestDetails: ChestDetails) => {
        this.activeChest = chestDetails;
        this.afterChest();
        this.opening = false;
        this.animateChestOpen();
        this.getProductsByCategory();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  changeChest(chest: Chest) {
    this.apiChestsService.playerChestsId(chest.player_chest_id)
      .subscribe((chestDetails: ChestDetails) => {
        this.activeChest = chestDetails;
        if (this.activeChest.status === this.CHEST_STATUS.USED) {
          this.skipClaimAnimation = true;
          this.opacityOpen = false;
        }
        this.afterChest();
      }, (errResp) => {
        this.dialogService.openAlertErrorApi({errResp});
      });
  }

  setAllowOpenChest() {
    const allowStatus = this.activeChest.status === this.CHEST_STATUS.CLOSED;
    const allowHaveKey = !this.activeChest.product_open_prices.find((product: ProductDetailsBalance) => !product.have);
    this.allowOpenChest = allowStatus && allowHaveKey;
    this.setErrorOpen();
    this.setAllowOpenError();
  }

  setErrorOpen() {
    this.errorOpen = null;

    if (!this.allowOpenChest) {
      const findNoHaveProduct = this.activeChest.product_open_prices.find((product: ProductDetailsBalance) => !product.have);

      if (findNoHaveProduct) {
        this.errorOpen = `Wymagany: ${findNoHaveProduct.name}`;
      }
    }
  }

  setAllowOpenError() {
    this.allowOpenError = null;

    if (this.allowOpenChest && this.activeChest.product_open_prices.length > 0) {
      const findHaveProduct = this.activeChest.product_open_prices.find((product: ProductDetailsBalance) => product.have);

      if (findHaveProduct) {
        this.allowOpenError = `Otwierając skrzynię, <br> zużyjesz ${findHaveProduct.name}!`;
      }
    }
  }

  afterChest() {
    // GET PRODUCTS
    this.activeChest.product_rewards = this.productPlayerService.getProducts(this.activeChest.product_rewards);
    this.activeChest.product_open_prices = this.productPlayerService.getProducts(this.activeChest.product_open_prices);

    // GET CURRENCIES
    this.activeChest.currency_rewards = <CurrencyDetails[]>this.currencyService.getCurrencyDefinitions(this.activeChest.currency_rewards);
    this.activeChest.currency_open_prices = <CurrencyDetails[]>this.currencyService.getCurrencyDefinitions(this.activeChest.currency_open_prices);

    this.setAllowOpenChest();
  }

  close() {
    this.matDialogRef.close();
  }

  private animateChestOpen() {
    setTimeout(() => {
      gsap
        .to('#chests-dialog .animate-opened', 1, {opacity: 1});
      gsap
        .to('#chests-dialog .animate-closed', 1, {opacity: 0});
    }, 100);
    if (this.activeChest.rewards_count !== 0 || null) {
      this.animatePrizesChestOpen();
      setTimeout(() => {
        this.opacityOpen = false;
      }, 200);
    }
  }

  private animatePrizesChestOpen() {
    setTimeout(() => {
      gsap
        .set('#chests-dialog .prizes', {opacity: 0});
      gsap
        .to('#chests-dialog .prizes', 1, {opacity: 1});
    }, 100);
  }
}

export interface Chest {
  player_chest_id: number;
  status: number;
  chest_id: number;
  product_id: number;
  name: string;
  description: string;
  icon: string;
}

interface ChestDetails extends Chest {
  rewards_count: number;
  category: any;
  product_open_prices: ProductAmount[];
  currency_open_prices: (Currency | CurrencyDetails)[];
  decoration_icon: string;
  product_rewards: any[];
  currency_rewards: (Currency | CurrencyDetails)[];
}

interface ChestDetailsProcess extends ChestDetails {
  iconUrl: string;
}
